import React from "react";
import { NumericFormat as NumberFormat } from 'react-number-format';
import PropTypes from 'prop-types';
//import InfoTooltip from './InfoTooltip';

export const CustomNumberFormatSimple = ({ name, value, onChange, onBlur, style, min, max, integer, suffix, errorMessage, inputRef, decimalScale, ...rest }) => {

  let valuesTmp = {}
  return (
    <React.Fragment>
       <NumberFormat
          style={{textAlign: "right", width: "100% !important", ...style}}
          value={value ? Number.parseFloat(value).toFixed(15) : value}
          name={name}
          id={name}
          thousandSeparator={' '}
          decimalSeparator={'.'}
          valueIsNumericString={true}
          //suffix={suffix}
          min={min}
          max={max}
          getInputRef={inputRef}
          decimalScale={integer ? 0 : decimalScale || 2}
          onValueChange={values => (valuesTmp = values)}
          onBlur={onBlur&&onBlur}
          onChange={(e) => onChange ?
            (valuesTmp.value !== undefined || valuesTmp.value === '') && onChange({
              target: {
                value: (min !== undefined || max !== undefined) ? clampNum(valuesTmp.floatValue, min, max) : valuesTmp.floatValue,
                name: name,
                formattedValue: valuesTmp.formattedValue,
              },
            }) : null
          }
          fixedDecimalScale={true}
          onClick={rest.displayType === 'text' ? null : e => e.target.select()}
          {...rest}
        />
        {!suffix && errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </React.Fragment>
  );
};

export const CustomNumberFormat = ({ name, title, suffix, errorMessage, extraClass, suffixClass, noInfoText, isInvalid, ...rest }) => {
  return (
    <div className={extraClass?"form-group " + extraClass :"form-group"}>
      <label htmlFor={name} className="form-label">
        {title ? title : null}
        {/* {(noInfoText || !title) ? <></> : <InfoTooltip name={name} />} */}
      </label>
      <div className="input-group">
        <CustomNumberFormatSimple
            className={isInvalid ? "form-control is-invalid" : "form-control"}
            name={name}
            {...rest}
          />
          {suffix && <div className="input-group-append">
            <span className={suffixClass ? "input-group-text " + suffixClass : "input-group-text"}>{suffix}</span>
          </div> }
          {errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
      </div>
    </div>
  );
};

export const PercentageFormat = props =>
  <CustomNumberFormat
    suffix={'%'}
    min={props.min || 0}
    max={props.max || 100}
    {...props}
  />


export const PercentageFormatSimple = props =>
    <div className="input-group">
        <CustomNumberFormatSimple
          min={props.min || 0}
          max={props.max || 100}
          className={props.className ? props.className: "form-control custom-form-control text-right custom-form-control-small-padding"}
          {...props}
        />
        {props.suffix && <div className="input-group-append custom-input-group-append">
          <span className="input-group-text custom-input-group-text-no-padding">{props.suffix}</span>
        </div> }
    </div>


const clampNum = (value, min, max) => {
  if(min !== undefined && value < min) {
    return min;
  } else if(max !== undefined && value > max) {
    return max;
  } else {
    return value;
  }
}

CustomNumberFormatSimple.propTypes = {
  // value: PropTypes.number, *can be string*
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

CustomNumberFormat.propTypes = {
  // value: PropTypes.number, *can be string*
  name: PropTypes.string.isRequired,
};

PercentageFormat.propTypes = {
  // value: PropTypes.number, *can be string*
  name: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

PercentageFormatSimple.propTypes = {
  // value: PropTypes.number, *can be string*
  name: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};
