import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tab, Tabs } from 'react-bootstrap';
import QuestionDetails from './QuestionDetails';
import { PencilFill, Plus, X, Download } from 'react-bootstrap-icons';
import APIs from '../../api/apiCalls';
import { withToast, withToastOnError } from '../../api/util';
import toast from '../../components/Toaster';
import ImportQuestions from './ImportQuestions';
import ExportQuestions from './ExportQuestions';
import { DeleteModal } from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';
import { difficulty, questionTypes } from '../constants';
import {download_file} from '../Raffles/downloadFile';

const Questions = (props) => {
  const { questionBank, language } = props;
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [ageGroups, setAgeGroups] = useState([]);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [questionBanks, setQuestionBanks] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [objEditDelete, setObjEditDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('active');

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      getQuestionCategories();
      getQuestionBanks();
      getAgeGroups();
      getQuestionsByQuestionBank();
    };
    fetchData();
  }, [questionBank]);

  const getQuestionsByQuestionBank = async() =>  {
    try {
      let res = await APIs.getQuestionsByQuestionBank(questionBank?.id ? questionBank.id : questionBank, language);
      setQuestions(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getAgeGroups = async() =>  {
    try {
      let res = await APIs.getAgeGroups(language);
      setAgeGroups(res.data)
    } catch (err) {
      console.error(err);
    }
  }

  const getQuestionCategories = async() =>  {
    try {
      let res = await APIs.getQuestionCategories(language);
      setQuestionCategories(res.data.filter(cat => !cat.is_hidden))
    } catch (err) {
      console.error(err);
    }
  }

  const getQuestionBanks = async() =>  {
    try {
      let res = await APIs.getQuestionBanks();
      setQuestionBanks(res.data);
      setShowQuestionModal(false);
    } catch (err) {
      console.error(err);
    }
  }

  const importQuestions = async(obj) => {
    setLoading(true);
    const data = new FormData();
    data.append('category', obj.category);
    data.append('question_bank', obj.question_bank);
    data.append('questions', obj.questions);
    try {
      const res = await withToastOnError(APIs.importQuestions(data));
      if(res.status === 200) {
        toast.success(<>
        <div>{t('messages.obj_imported', { obj: 'questions'})}</div>
        <div> {t('added_questions')}: {JSON.parse(res.data)[0].total_added_questions}</div>
        </>)}
      getQuestionsByQuestionBank();
      setShowImportModal(false);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setShowImportModal(false);
      setLoading(false);
    }
  }

  const createQuestion = async(obj) =>  {

      const objTmp = {...obj,
        age_group: obj.age_group.id ? obj.age_group.id : obj.age_group,
        category: obj.category.id ? obj.category.id : obj.category,
        question_bank: obj.question_bank.id ? obj.question_bank.id : obj.question_bank,
      }
      try {
        setShowQuestionModal(false);
        if(objTmp.question_type === 'pairing') {
          objTmp.choices = createChoices(objTmp.choices);
          let res = await withToast(APIs.createQuestion(objTmp, language));
          let res1 = await APIs.getQuestionById(res.data.id, language);
          if(res1.data.choices.filter(el => !el.correct_pair)?.length > 0) {
            const pairs = createPairs(objTmp.choices, res1.data.choices)
            await APIs.editPairQuestion(res.data.id, pairs);
          }
        }
        else {
          if(objTmp?.question_type === 'free') {
            objTmp.choices = []
          }
          const res = await withToast(APIs.createQuestion(objTmp, language));
        }
        getQuestionsByQuestionBank();
      } catch (err) {
        console.error(err);
      }
  }

  const createChoices = (choices) => {
    const additionalChoices = choices.filter(choise => !choise.id).map(choise =>{
      return ({value: choise.correct_pair, is_left: false, correct_pair: choise.value})
    })
    return [...choices, ...additionalChoices];
  }

  const getIdByValue = (choices, value) => {
    return choices.find(el => el.value === value)?.id
  }

  const getPairByValue = (choices, value) => {
    return choices.find(el => el.correct_pair === value)?.value
  }

  const createPairs = (objTmp, createdChoices) => {
    const pairs = []
    createdChoices.forEach( el => {
      if(!el.correct_pair) {
        if(el.is_left) {
          const val = getIdByValue(createdChoices, getPairByValue(objTmp, el.value));
          pairs.push({pair1: el.id, pair2: val});
        }
      }
    })
    return pairs;
  }

  const editQuestion = async(id, obj) =>  {
    const objTmp = {...obj,
      age_group: obj.age_group.id ? obj.age_group.id : obj.age_group,
      category: obj.category.id ? obj.category.id : obj.category,
      question_bank: obj.question_bank.id ? obj.question_bank.id : obj.question_bank,
    }
    try {
      setShowQuestionModal(false);
      if(objTmp?.question_type === 'pairing') {
        objTmp.choices = createChoices(objTmp.choices);
        await withToast(APIs.editQuestion(id, objTmp, language));
        let res1 = await APIs.getQuestionById(id, language);
        if(res1.data.choices.filter(el => !el.correct_pair)?.length > 0)
        {
          const pairs = createPairs(objTmp.choices, res1.data.choices)
          await APIs.editPairQuestion(id, pairs);
        }
      }
      else {
        const res = await withToast(APIs.editQuestion(id, objTmp, language));

      }
      getQuestionsByQuestionBank();
    }
    catch (err) {
      console.error(err);

      setShowQuestionModal(false);
    }
  }

  const deleteQuestion = async(id) =>  {
    try {
      await withToast(APIs.deleteQuestion(id));
      setSelectedQuestion(null);
      setShowDeleteModal(false);
      getQuestionsByQuestionBank();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div>
          {row.value}
      </div>
    )
  }

  const columns = [
    // {
    //   Header: t('id'),
    //   accessor: "id",
    //   Cell: cell,
    // },
    {
      Header: t('question'),
      accessor: "question_text",
      Cell: cell,
    },
    {
      Header: t('question_type'),
      accessor: "question_type",
      Cell: (row) => {
        return(
          <div className="pull-right">
              {t(row.value)}
          </div>
        )
      },
      Filter: SelectWithOptionsColumnFilter,
      selectOptions: [{label: t('all'), value: ''}, ...questionTypes(t)],
    },
    {
      Header: t('category'),
      accessor: "category.name",
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [{label: t('all'), value: ''}, ...questionCategories.map(el => ({label: el.name, value: el.id}))],
    },
    // {
    //   Header: t('question_bank'),
    //   accessor: "question_bank.name",
    //   Cell: cell,
    //   Filter: SelectWithOptionsColumnFilter,
    //   filter: 'filterObjectIdName',
    //   selectOptions: [{label: t('all'), value: ''}, ...questionBanks.map(el => ({label: el.name, value: el.id}))],
    // },
    {
      Header: t('difficulty'),
      accessor: 'difficulty',
      Cell: (row) => {
        switch (row.value) {
          case 2:
            return(<div>{t('easy')}</div>)
          case 5:
            return(<div>{t('medium')}</div>)
          default:
            return(<div>{t('hard')}</div>)
        }
      },
      Filter: SelectWithOptionsColumnFilter,
      selectOptions: [{label: t('all'), value: ''}, ...difficulty(t)],
    },
    {
      Header: t('age_group'),
      accessor: "age_group.name",
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [{label: t('all'), value: ''}, ...ageGroups.map(el => ({label: el.name, value: el.id}))],
    },
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center">
            <PencilFill className="custom-icon me-1" color="gray" size={16}
            onClick={() => handleEdit(row)}/>
            <X className="custom-icon" color="red" size={20}
            onClick={() => {setShowDeleteModal(true), setObjEditDelete(row.row.original.id)}}/>
          </div>
        );
      },
      disableFilters: true,
      width: 'auto',
      minWidth: 60,
      maxWidth: 60,
    },
  ]

  const handleEdit = (row) => {
    setShowQuestionModal(true)
    setSelectedQuestion(row.row.original.id)
  };

  const exportQuestions = async (fileName) => {
    try {
      const res = await withToastOnError(APIs.exportQuestions(questionBank));
      download_file(res, fileName);
    }
    catch(e) {
      console.error(e);
    }
    finally {
      setShowExportModal(false);
    }

  }

  const data = React.useMemo(() => questions)
  return(
    <>
      <h3>{t('questions')}</h3>
        <Button size="sm" className="mb-2 custom-button me-2" onClick={()=>{setShowQuestionModal(true), setSelectedQuestion(null)}}>
            <span><Plus color="white" size={20}/></span>{t('buttons.add')}
        </Button>
        <Button size="sm" className="mb-2 custom-button me-2" onClick={()=>{setShowImportModal(true)}}>
            <span><Plus color="white" size={20}/></span>{t('buttons.import')}
        </Button>
        <Button size="sm" className="mb-2 custom-button" onClick={()=>{setShowExportModal(true)}}>
            <span style={{marginRight: "5px"}}><Download color="white" size={15}/></span>{t('export')}
        </Button>
        {questions &&
          <Tabs id="content"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="active" title={t('active')}>
            <CheckboxTablePaginated
              columns={columns}
              data={data.filter(el => el.status === 'active')}
              total={false}
              filterTable={true}
              pagination={true}
              selectedId={selectedQuestion}
            />
          </Tab>
          <Tab eventKey="development" title={t('development')}>
            <CheckboxTablePaginated
                columns={columns}
                data={data.filter(el => el.status === 'development')}
                total={false}
                filterTable={true}
                pagination={true}
                selectedId={selectedQuestion}
              />
          </Tab>
          <Tab eventKey="testing" title={t('testing')}>
            <CheckboxTablePaginated
              columns={columns}
              data={data.filter(el => el.status === 'testing')}
              total={false}
              filterTable={true}
              selectedId={selectedQuestion}
            />
          </Tab>
          <Tab eventKey="expired" title={t('expired')}>
            <CheckboxTablePaginated
                columns={columns}
                data={data.filter(el => el.status === 'expired')}
                total={false}
                filterTable={true}
                pagination={true}
                selectedId={selectedQuestion}
              />
          </Tab>
        </Tabs>
        }
        {showQuestionModal && <QuestionDetails
          selectedQuestion={selectedQuestion}
          showQuestionModal={showQuestionModal}
          onCancel={() => setShowQuestionModal(false)}
          ageGroups={ageGroups}
          questionCategories={questionCategories}
          questionBanks={questionBanks}
          onOK={(question) => (selectedQuestion?.id || selectedQuestion) ? editQuestion(question.id, question) : createQuestion(question)}
          language={language}
          questionBank={questionBank}
        />}
        {showImportModal && <ImportQuestions
          questionBank={questionBank}
          questionBanks={questionBanks}
          categories={questionCategories}
          onCancel={() => setShowImportModal(false)}
          onOK={obj => importQuestions(obj)}
          loading={loading}
        />}
        {showExportModal && <ExportQuestions
          questionBankName={questionBanks.find(q => q.id === questionBank)?.name}
          onCancel={() => setShowExportModal(false)}
          onOK={fileName => exportQuestions(fileName)}
        />}
         {showDeleteModal && <DeleteModal
          onOK={() => deleteQuestion(objEditDelete)}
          onCancel={() => setShowDeleteModal(false)}
        />}
    </>
  )
}
export default Questions;
