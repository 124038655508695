import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import { Formik, Field } from 'formik';
import * as yup from "yup";
import { FormTextField, FormDatePickerField, FormCheckbox, FormSelectField } from '../../components/FormField';
import { useAuth } from '../../LoginContext';
import UsersAPI from '../../api/users';

const UserDetails = (props) => {
  const { selectedRow, onCancel, onCreate, onEdit } = props;
  const [userTmp, setUserTmp] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const formRef = useRef();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [unvalidPassword, setUnvalidPassword] = useState(null);
  const [regionOptions, setRegionOptions] = useState([]);

  const initialState = {
    show_password_reset: false,
    username: '',
    email: '',
    birth_date: '',
    password: '',
    is_test_user: true,
    is_staff_user: true,
    is_admin: false,
    region: ''
  }

  const schema = yup.object({
    username: yup.string().required(t('validation.is_required', {obj: t('username')})),
    email: yup.string().email().required(t('validation.is_required', {obj: t('email')})),
    region: yup.string().required(t('validation.is_required', {obj: t('region')})),
    //birth_date: yup.string().required(t('validation.is_required', {obj: t('birth_date')})),
    //password: yup.string().required(t('validation.is_required', {obj: t('password')})),
    reset_password: yup.string()
      .when('show_password_reset', {
        is: true,
        then: schema => schema.required(t('validation.is_required', {obj: t('password')})),
      }),
    confirm_reset_password: yup.string()
      .when('show_password_reset', {
        is: true,
        then: schema => schema
        .oneOf([yup.ref('reset_password'), null], t('validation.passwords_dont_match'))
        .required(t('validation.is_required', {obj: t('confirm_password')})),
      }),
  });


  useEffect(() => {
    const setData = async () => {
      const res = await UsersAPI.getRegions();
      const regOptions = res.data.map((region) => {
        return({
            label: region.name,
            value: region.id
        })
      });
      setRegionOptions(regOptions);
      if(selectedRow?.id) {
        setUserTmp(selectedRow)
      }
      else {
        setUserTmp(initialState)
      }
    };
    setData();
  }, [])

  const doSubmit = async (val) => {
    if (formRef.current.isValid) {
      if(userTmp?.id) {
        let possibleErrors;
        if(!val.show_password_reset) {
          const {reset_password, ...editObj} = val;
          await onEdit(editObj);
        }
        else possibleErrors = await onEdit(val);
        if(possibleErrors) {
          setUnvalidPassword(possibleErrors);
        }
        else onCancel();
      }
      else {
        const possibleErrors = await onCreate(val);
        if(possibleErrors) {
          let errors = [];
          Object.values(possibleErrors).forEach((errorCategory) => {
            errors = [...errors, ...errorCategory]
          })
          setUnvalidPassword(errors);
        }
        else {
          onCancel();
        }
      }
    }
  }

  const renderForm = (values, form) => {
    return(
      <fieldset disabled={!currentUser.is_admin }>
        <Row>
          <Col>
            <FormTextField
              title={t('username')}
              name={"username"}
              disabled={!currentUser.is_admin || !values?.is_staff_user }
            />
          </Col>
          <Col>
            <FormTextField
              title={t('email')}
              name={"email"}
              disabled={!currentUser.is_admin || !values?.is_staff_user }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormDatePickerField
              title={t('birth_date')}
              name={"birth_date"}
              minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
              maxDate={new Date()}
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              disabled={!currentUser.is_admin || !values?.is_staff_user }
            />
          </Col>
          <Col>
            <FormSelectField
              disabled={!currentUser.is_admin || !values?.is_staff_user }
              name="region"
              title={t('region')}
              options={regionOptions}
            />
          </Col>
        </Row>
        {(values?.id && !values?.is_staff_user) && <Row>
          <Col lg={6}>
            <FormTextField
              name='language'
              title={t('language')}
              value={
                values.language === 'hu'
                ? t('hu')
                : t('en')
              }
              disabled={!values?.is_staff_user}
            />
          </Col>
        </Row>}
        {!selectedRow?.id && <Row>
          <FormTextField
            title={t('password')}
            name={"password"}
            type={"password"}
            autoComplete='new-password'
          />
        </Row>}
        <Row>
          {values?.id && values?.is_staff_user && currentUser.is_admin &&
          <Field>
            {({ field, form, meta }) => {
                return (
                  <>
                  {!field.value.show_password_reset &&
                  <Col lg={2}>
                    <Button
                       style={{width:"100%"}}
                       size="sm"
                       className="mt-4 custom-button"
                       onClick={() => form.setFieldValue('show_password_reset', true)}>
                        <span>{t('password_reset.reset_password')}</span>
                    </Button>
                  </Col>
                  }
                  </>
                )
              }
            }
          </Field>
          }
          {values?.show_password_reset &&
            <>
              <Col lg={6}>
                <FormTextField
                  title={t('password')}
                  name={"reset_password"}
                  type={"password"}
                  placeholder={t('password')}
                />
              </Col>

              <Col lg={6}>
                <FormTextField
                  title={t('confirm_password')}
                  name={"confirm_reset_password"}
                  type={"password"}
                  placeholder={t('confirm_password')}
                />
              </Col>
            </>
          }
        </Row>
        {!values?.is_staff_user &&
        <Row>
          <Col>
            <div>
              <FormCheckbox
                title={t('is_test_user')}
                label={t('is_test_user')}
                name={`is_test_user`}
              />
            </div>
          </Col>
        </Row>
        }
        <Row>
        {unvalidPassword?.length > 0 &&
          <div className="mt-2">
           <span style={{color:"#dc3545"}}>
             {unvalidPassword.map((error) => <span>{error}<br/></span>)}
           </span>
          </div>
         }
        </Row>
      </fieldset>
    )
  }

  return(
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={userTmp}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        form,
        values,
        dirty
      }) => (
      <Form id="form-content">
         <CustomModal
          title={selectedRow?.id ? t('edit_obj', {obj: 'user'}) : t('new_obj', {obj: 'user'})}
          content={renderForm(values, form)}
          contentStyle={{fontSize: "12px"}}
          onCancel={onCancel}
          onOK={() =>  handleSubmit()}
          okButtonText={selectedRow?.id ? t('buttons.ok') : t('buttons.save')}
          hideOkButton={true}
          modified={dirty}

          />
      </Form>
    )}
  </Formik>
  )
}
export default UserDetails;
