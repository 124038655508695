import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { DeleteModal } from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTableServerSidePagination';
import TourDetails from './TourDetails';
import TourAPIs from '../../api/tours';
import APIs from '../../api/apiCalls';
import { withToast } from '../../api/util';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';
import { TitleComponent } from '../../components/TitleComponent';
import ToursTable from './ToursTable';
import { durationOptions, routeTypeOptions } from '../constants';

const Tours = () => {
  const [selectedTour, setSelectedTour] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objEditDelete, setObjEditDelete] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [key, setKey] = useState('active');
  const [language, setLanguage] = useState('hu');
  const [refresh, setRefresh] = useState(false);
  const [categories, setCategories] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const setData = async () => {
      getCategories();
    };
    setData();
  }, []);

  const getCategories = async () => {
    try {
      let res = await APIs.getQuestionCategories(language);
      setCategories(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const createTour = async (obj) => {
    try {
      let res = await withToast(TourAPIs.createTour(obj, language));
      setSelectedTour(res.data);
      setShowDetailsModal(false);
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const editTour = async (obj) => {
    try {
      const id = obj.id;
      delete obj.id;
      await withToast(TourAPIs.editTour(id, obj, language));
      setShowDetailsModal(false);
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteTour = async () => {
    try {
      await withToast(TourAPIs.deleteTour(objEditDelete));
      setSelectedTour(null);
      setShowDeleteModal(false);
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const cell = (row) => {
    return <div>{row.value}</div>;
  };

  const cellNum = (row) => {
    return <div className="float-end">{row.value}</div>;
  };

  const cellRouteType = (row) => {
    return (t(`${row.value}`));
  };

  const cellDuration = (row) => {
    return (t(`${row.value}min`));
  };

  const columns2 = [
    {
      Header: t('name'),
      accessor: 'name',
      Cell: cell,
    },
    {
      Header: t('route_type'),
      accessor: 'route_type',
      Cell: cellRouteType,
      Filter: SelectWithOptionsColumnFilter,
      selectOptions: [{ label: t('all'), value: '' }, ...routeTypeOptions(t)],
    },
    {
      Header: t('duration'),
      accessor: 'duration',
      Cell: cellDuration,
      Filter: SelectWithOptionsColumnFilter,
      selectOptions: [{ label: t('all'), value: '' }, ...durationOptions(t)],
    },
    {
      Header: t('category'),
      accessor: 'category.name',
      id: 'category',
      Cell: cell,
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [
        { label: t('all'), value: '' },
        ...categories.map((el) => ({ label: el.name, value: el.id })),
      ],
    },
    {
      Header: t('checkpoints'),
      accessor: 'items_amount',
      Cell: cellNum,
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: (row) => {
        return (
          <div className="text-center">
            <PencilFill
              className="custom-icon me-1"
              color="gray"
              size={16}
              onClick={() => {
                setShowDetailsModal(true);
                setSelectedTour(row.row.original);
              }}
            />
            <X
              className="custom-icon"
              color="red"
              size={20}
              onClick={() => {
                setShowDeleteModal(true);
                setObjEditDelete(row.row.original.id);
              }}
            />
          </div>
        );
      },
      disableFilters: true,
      width: 'auto',
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  return (
    <>
      <TitleComponent
        title={t('tours')}
        language={language}
        setLanguage={setLanguage}
      />
      <Row>
        <Col lg={1}>
          <Button
            size="sm"
            className="mb-2 custom-button"
            onClick={() => {
              setSelectedTour(null);
              setShowDetailsModal(true);
            }}
          >
            <span>
              <Plus color="white" size={20} />
            </span>
            {t('buttons.add')}
          </Button>
        </Col>
      </Row>
      <Tabs
        id="content"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="active" title={t('active')}>
          {key === 'active' && <>
            <ToursTable
              columns={columns2}
              refresh={refresh}
              selectedTour={selectedTour}
              language={language}
              status={'active'}
            />
            </>
          }
        </Tab>
        <Tab eventKey="development" title={t('development')}>
          {key === 'development' && <>
            <ToursTable
              columns={columns2}
              refresh={refresh}
              selectedTour={selectedTour}
              language={language}
              status={'development'}
            />
            </>
          }
        </Tab>
        <Tab eventKey="testing" title={t('testing')}>
          {key === 'testing' && <>
            <ToursTable
              columns={columns2}
              refresh={refresh}
              selectedTour={selectedTour}
              language={language}
              status={'testing'}
            />
            </>
          }
        </Tab>
        <Tab eventKey="expired" title={t('expired')}>
          {key === 'expired' && <>
            <ToursTable
              columns={columns2}
              refresh={refresh}
              selectedTour={selectedTour}
              language={language}
              status={'expired'}
            />
            </>
          }
        </Tab>
      </Tabs>
     
      {showDetailsModal && (
        <TourDetails
          onCancel={() => setShowDetailsModal(false)}
          selectedRow={selectedTour}
          onCreate={(obj) => createTour(obj)}
          onEdit={(obj) => editTour(obj)}
          language={language}
          categories={categories}
          onRefresh={() => setRefresh(!refresh)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onOK={() => deleteTour()}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};
export default Tours;
