import React, { useState, useRef, useCallback, useMemo } from 'react';
import UsersAPI from '../../api/users';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTableServerSidePagination';

const UsersTable = (props) => {
  const { columns, refresh, selectedUser, isStaff, archived } = props;
  const [users, setUsers] = useState([]);
  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current
    if (fetchId === fetchIdRef.current) {
      let queryObj = {};
      if(filters && filters.length > 0){
        filters.forEach(function(filter){
          if(filter?.value) {
            if(filter.id === 'birth_date' || filter.id === 'last_active') {
              queryObj[filter.id] = filter.value[0] + ',' + filter.value[1];
            }
            else {
              queryObj[filter.id] = filter.value;
            }
          }
        })
      }
      if(archived) {
        queryObj['archived'] = true;
      }
      else {
        queryObj['is_staff'] = isStaff;
      }
      queryObj['_pagesize'] = pageSize;
      queryObj['_page'] = pageIndex + 1;
      getUsers(queryObj);
    }
  }, [refresh]);

  const getUsers = async(queryObj) =>  {
    try {
      const res = await UsersAPI.getUsers(queryObj ? queryObj : null);
      setPageCount(res.headers?.total_pages)
      setUsers(res.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  return (
    <>
      <CheckboxTablePaginated
        columns={columns}
        data={useMemo(() => users)}
        total={false}
        filterTable={true}
        pagination={true}
        selectedId={selectedUser?.id}
        checkboxHook={false}
        fetchData={fetchData}
        pageCount={pageCount}
        loading={loading}
      />
    </>
  );
}
export default UsersTable;
