import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import { jsonToFormData } from './util';
import axios from 'axios';

class Checkpoints {

  //Checkpoints
  async getCheckpoints(language, queryObj) {
    const endpoint = endpoints.get_checkpoints + language;
    const res = await RestHelper.get(endpoint, queryObj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getCheckpointById(id, language) {
    const endpoint = endpoints.get_checkpoint + id + '/' + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createCheckpoint(obj, language) {
    const endpoint = endpoints.create_checkpoint + language;
    const res = await RestHelper.post(endpoint, jsonToFormData(obj), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'checkpoint'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'checkpoint'})
      })
    }
  }

  async editCheckpoint(id, obj, language) {
    const endpoint = endpoints.edit_checkpoint + id + '/' + language;
    const res = await RestHelper.put(endpoint, jsonToFormData(obj), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'checkpoint'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'checkpoint'})
      })
    }
  }

  async deleteCheckpoint(id) {
    const endpoint = endpoints.delete_checkpoint + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'checkpoint'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'checkpoint'})
      })
    }
  }

  async deleteCheckpoints(obj) {
    const endpoint = endpoints.bulk_delete_checkpoint;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'checkpoint'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'checkpoint'})
      })
    }
  }

  async deleteCheckpointImage(id, obj) {
    const endpoint = endpoints.delete_checkpoint_image + id + '/delete_image' ;
    const res = await RestHelper.post(endpoint, obj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'picture'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'picture'})
      })
    }
  }

  async exportCheckpoints(param, queryObj) {
    const endpoint = endpoints.export_checkpoints;
    const res = await RestHelper.post(endpoint, param,
      {
        params: queryObj,
        responseType: 'blob'
      });
    if (res.status !== 200) {
       throw new Error(i18next.t('messages.obj_could_not_be_exported', { obj: 'message_qr_codes'}))
    }
    else {
      return ({
				res,
        message: i18next.t('messages.obj_exported', { obj: 'message_qr_codes'})
      })
    }
  }

}

export default new Checkpoints();
