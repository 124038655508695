import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import axios from 'axios';

import App from './app';
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from './i18n';
import UserAPI from './api/users';

import { AuthProvider } from './LoginContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/style.scss';

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 500;
};
axios.defaults.timeout = 300000; // 5mins

const domNode = document.getElementById('coinapp-wrapper');
const root = createRoot(domNode);


root.render(
  <HashRouter future={{ v7_startTransition: true }}>
      <I18nextProvider i18n={i18next}>
        <AuthProvider subPages ={(<App />)} />
      </I18nextProvider>
  </HashRouter>

)
