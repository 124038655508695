import React, { useState, useRef, useCallback, useMemo } from 'react';
import InfoContentAPI from '../../api/infoContent';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTableServerSidePagination';

const InfoContentTable = (props) => {
  const { columns, refresh, selectedInfoContent, language, status } = props;
  const [infoContents, setInfoContents] = useState([]);
  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current
    if (fetchId === fetchIdRef.current) {
      let queryObj = {};
      if(filters && filters.length > 0){
        filters.forEach(function(filter){
          if(filter?.value) {
            queryObj[filter.id] = filter.value;
          }
        })
      }
      queryObj['status'] = status;
      queryObj['_pagesize'] = pageSize;
      queryObj['_page'] = pageIndex + 1;
      getInfoContents(queryObj);
    }
  }, [refresh, language]);

  const getInfoContents = async(obj) =>  {
    try {
      let res = await InfoContentAPI.getInfoContents(language, obj);
      setPageCount(res.headers.total_pages)
      setInfoContents(res.data)
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  return (
    <>
      <CheckboxTablePaginated
        columns={columns}
        data={useMemo(() => infoContents)}
        total={false}
        filterTable={true}
        pagination={true}
        selectedId={selectedInfoContent?.id}
        checkboxHook={false}
        fetchData={fetchData}
        pageCount={pageCount}
        loading={loading}
      />
    </>
  );
}
export default InfoContentTable;
