import React, {useEffect, useState, useRef} from 'react';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTablePaginated';
import { Row, Col, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Download, PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../components/Modal';
import { FormDatePickerField, FormSelectField, FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import { withToast } from '../../api/util';
import { DeleteModal } from '../../components/Modal';
import APIs from '../../api/apiCalls';
import RafflesAPI from '../../api/raffles';
import WinnerRegistration from './WinnerRegistration';
import { download_file } from './downloadFile';
import { TitleComponent } from '../../components/TitleComponent';
import { SelectWithOptionsColumnFilter } from '../../components/ReactTable/Filters';

const Raffles = (props) => {

  const [selectedRaffle, setSelectedRaffle] = useState(null);
  const [deleteRaffle, setDeleteRaffle] = useState(null);
  const formRef = useRef();
  const {t} = useTranslation();
  const [raffleData, setRaffleData] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [winnerRegistrationModal, setWinnerRegistrationModal] = useState(false)
  const [selectedRaffleId, setSelectedRaffleId] = useState(null);
  const [language, setLanguage] = useState('hu');
  
  const refreshRaffles = async () => {
    try {
      const res = await RafflesAPI.getRaffles(language);
      setRaffleData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const [ rafflesRes, campaignsRes] = await Promise.all([
        RafflesAPI.getRaffles(language),
        APIs.getCampaigns(language),
      ])
      setRaffleData(rafflesRes.data);
      setCampaigns(campaignsRes.data.map(campaign => {
          return {label: campaign.name, value: campaign.id, available_until: campaign.available_until }
      }));
    }
    fetchData();
  }, [language])


  const exportRaffleParticipants = async (id) => {
    try {
      const res = await withToast(RafflesAPI.exportRaffleParticipants(id));
      download_file(res.res);
    } catch (err) {
      console.error(err);
    }
  }

  const columns = [
    {
      Header: t('name'),
      accessor: 'name'
    },
    {
      Header: t('campaign'),
      accessor: 'campaign.name',
      Cell: (row) => {
        return(
          <div className="pull-right">
              {row.value}
          </div>
        )
      },
      Filter: SelectWithOptionsColumnFilter,
      filter: 'filterObjectIdName',
      selectOptions: [
        {label: t('all'), value: ''}, 
        ...campaigns
      ],
    },
    {
      Header: t('deadline'),
      accessor: 'deadline',
      disableFilters: true,
    },
    {
      Header: t('lottery_draw_date'),
      accessor: 'raffle_date',
      disableFilters: true,
    },
    {
      width: 80,
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        if(row.row.isGrouped) return <></>
        return (
          <div className="text-center">
            <OverlayTrigger
              placement="top"
              trigger={['hover', 'focus']}
              overlay={<Tooltip id='top'> {t('export_participants')}</Tooltip>}>
                <Download className="custom-icon me-3" color="#AF9B78" size={18} onClick={()=> exportRaffleParticipants(row.row.original.id)} />
            </OverlayTrigger>
            <PencilFill className="custom-icon me-3" color="gray" size={16}
              onClick={() => setSelectedRaffle({...row.row.original, campaign: row.row.original.campaign.id})}/>
            <X className="custom-icon" color="red" size={20}
              onClick={() => setDeleteRaffle(row.row.original.id)}
            />
          </div>
        );
      },
      disableFilters: true,
    }
  ];

  const schema = yup.object({
    name: yup.string().required(t('validation.is_required', {obj: t('name')})),
    prize: yup.string().nullable(),
    deadline: yup.string().required(t('validation.is_required', {obj: t('deadline')})),
    campaign: yup.string().required(t('validation.is_required', {obj: t('campaign')})),
  });


  const doSubmit = async (values) => {
    if (formRef.current.isValid) {
      if(selectedRaffle?.id) {
        const res = await withToast(RafflesAPI.editRaffle(values.id, values, language));
        refreshRaffles();
        setSelectedRaffle(null);
      }
      else {
        const res = await withToast(RafflesAPI.createRaffle(values, language));
        refreshRaffles();
        setSelectedRaffle(null);
      }
    }
  }

  const handleDeleteRaffle = async () => {
    const res = await withToast(RafflesAPI.deleteRaffle(deleteRaffle));
    refreshRaffles();
    setDeleteRaffle(null);
  }

  const onCancel = () => {
    setSelectedRaffle(null);
  }

  return (
    <>
      <TitleComponent
        title={t('raffles')}
        language={language}
        setLanguage={setLanguage}
      />
      <Button size="sm" className="mb-2 custom-button me-2" onClick={()=>{
        setSelectedRaffle({ id: null, name: '', prize: '', deadline: '', campaign: ''})}}>
        <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <CheckboxTablePaginated
        columns={columns}
        data={raffleData}
        filterTable={true}
        selectedId={selectedRaffle?.id}
        pagination={true}
      />
      {selectedRaffle &&
        <Formik
          validationSchema={schema}
          innerRef={formRef}
          enableReinitialize
          initialValues={selectedRaffle}
          validateOnMount={true}
          onSubmit={(values) => {
            doSubmit(values)
          }}
        >
          {({
            handleSubmit,
            values,
            dirty,
            setFieldValue
          }) => (
            <>
             <Form id="form-content">
              <CustomModal
                title={selectedRaffle?.id ? t('edit_obj', {obj: 'raffle'}) : t('new_obj', {obj: 'raffle'})}
                contentStyle={{fontSize: "12px"}}
                onCancel={onCancel}
                onOK={() => handleSubmit()}
                okButtonText={t('buttons.save')}
                modified={dirty}
                disabledOKButton={!dirty}
                content={
                  <>
                    <Row>
                      <Col>
                        <FormTextField
                          title={t('name')}
                          name={"name"}
                        />
                      </Col>
                      <Col>
                      <FormSelectField
                          name="campaign"
                          title={t('campaign')}
                          options={campaigns}
                          onChange={(e, val) => {
                            setFieldValue('deadline', campaigns.find(camp => camp.value === val)?.available_until)
                          }}
                          disabled={selectedRaffle?.id ? true : false}
                       />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormDatePickerField
                          title={t('lottery_draw_date')}
                          name={"raffle_date"}
                          placeholder={t('lottery_draw_date')}
                          dateFormat="yyyy-MM-dd"
                        />
                      </Col>
                      <Col>
                        <FormDatePickerField
                          title={t('deadline')}
                          name={"deadline"}
                          dateFormat="yyyy-MM-dd"
                        />
                      </Col>
                    </Row>
                  </>
                }
              />
            </Form>
            </>
        )}
        </Formik>

      }
      {deleteRaffle && <DeleteModal
        onOK={() => handleDeleteRaffle(deleteRaffle)}
        onCancel={() => setDeleteRaffle(null)}
      />}
      {winnerRegistrationModal && <WinnerRegistration
        onCancel={() => setWinnerRegistrationModal(false)}
        onRefresh={() => setWinnerRegistrationModal(false)}
        raffleId={selectedRaffleId}
      />}
  </>
  )
}

export default Raffles;
