import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row } from 'react-bootstrap';
import TourAPIs from '../../api/tours';
import CustomModal from '../../components/Modal';
import { FormSelectField, FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from 'yup';
import { durationOptions, routeTypeOptions, statusOptions } from '../constants';
import { ImageBrowser } from '../../components/FileBrowser';
import { X } from 'react-bootstrap-icons';
import CheckpointAPIs from '../../api/checkpoints';
import TourItem from './TourItem';

const TourDetails = (props) => {
  const { selectedRow, onCancel, onCreate, language, onEdit, categories, onRefresh } =
    props;
  const [tourTmp, setTourTmp] = useState(null);
  const [checkpoints, setCheckpoints] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const formRef = useRef();

  const initialState = {
    name: '',
    status: null,
    description: '',
    image: '',
    route_type: 'thematic',
    duration: 30,
    category: null,
    items: [],
  };

  const schema = yup.object({
    name: yup
      .string()
      .max(255)
      .required(t('validation.is_required', { obj: t('name') })),
    status: yup
      .string()
      .nullable()
      .required(t('validation.is_required', { obj: t('status') })),
    description: yup.string().max(256),
    image: yup
      .string()
      .max(255)
      .required(t('validation.is_required', { obj: t('image') })),
    category: yup.string().when('route_type', {
      is: 'thematic',
      then: (schema) =>
        schema.required(t('validation.is_required', { obj: t('category') })),
      otherwise: (schema) => schema.nullable(),
    }),

    items: yup
      .array()
      .min(1, t('minimumOneItem'))
      .of(
        yup.object().shape({
          checkpoint: yup
            .string()
            .nullable()
            .required(t('validation.is_required', { obj: t('checkpoint') })),
          thumbnail: yup
            .string()
            .nullable()
            .required(t('validation.is_required', { obj: t('image') })),
          color_code: yup
            .string()
            .nullable()
            .required(t('validation.is_required', { obj: t('color_code') })),
        })
      )
      .test(
        'unique',
        t('different_items'),
        (value) => {
          if (!value) return true;
          const unique = value.filter(
            (v, i, a) =>
              a.findIndex(
                (t) => t.checkpoint === v.checkpoint
              ) === i
          );
          return unique.length === value.length;
        }
      ),
  });

  useEffect(() => {
    const setData = async () => {
      await getCheckpoints();
      if (selectedRow?.id) {
        const row = await getTourById(selectedRow.id);
        setTourTmp(row);
      } else {
        setTourTmp(initialState);
      }
    };
    setData();
  }, [selectedRow?.id, refresh]);

  const getTourById = async (id) => {
    try {
      const res = await TourAPIs.getTourById(id, language);
      const tmp = res.data;
      tmp.category = res.data.category?.id;
      tmp.items = res.data.items.map(el => {
        return {
          ...el,
          checkpoint: el.checkpoint.id
        }
      });
      return tmp;
    } catch (err) {
      console.error(err);
    }
  };

  const getCheckpoints = async () => {
    try {
      const res = await CheckpointAPIs.getCheckpoints(language, null);
      setCheckpoints(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const doSubmit = (values) => {
    if (formRef.current.isValid) {
      if (values?.id) {
        onEdit(getObjectToSave(values));
      } else {
        onCreate(getObjectToSave(values));
      }
    }
  };

  const getObjectToSave = (tour) => {
    const tourTmp = tour;

    if (tourTmp.image instanceof File == false) {
      delete tourTmp.image;
    }

    if (tourTmp.route_type === 'thematic') {
      tourTmp.category = tourTmp.category?.id
        ? tourTmp.category?.id
        : tourTmp.category;
    }
    else delete tourTmp.category;

    const newCheckpoints = tour?.items.filter(
      (tr) => tr.thumbnail instanceof File
    );

    if(newCheckpoints.length > 0) {
      tourTmp.files = newCheckpoints.map((t) => t.thumbnail);

      tourTmp.items = newCheckpoints.map((t) => {
        return {
          checkpoint: t.checkpoint,
          order: t.order,
          color_code: t.color_code
        };
      });
    }
    else {
      tourTmp.items = tourTmp.items.map((t) => {
        return {
          checkpoint: t.checkpoint,
          order: t.order,
          color_code: t.color_code
        };
      });
    }
    
    return tourTmp;
  };

  const renderForm = (values, setFieldValue, errors) => {
    return (
      <>
        <Row>
          <Col lg={6}>
            <FormTextField title={t('name')} name={'name'} />
          </Col>
          <Col lg={6}>
            <FormSelectField
              name="status"
              title={t('status')}
              options={statusOptions(t)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormSelectField
              name="route_type"
              title={t('route_type')}
              options={routeTypeOptions(t)}
            />
          </Col>
          {values?.route_type === 'thematic' && (
            <Col lg={3}>
              <FormSelectField
                name="category"
                title={t('category')}
                options={categories.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
              />
            </Col>
          )}
          <Col lg={3}>
            <FormSelectField
              name="duration"
              title={t('duration')}
              options={durationOptions(t)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormTextField
              title={t('description')}
              name={'description'}
              as="textarea"
              rows={12}
            />
          </Col>
          <Col lg={6}>
            <ImageBrowser
              title={t('image')}
              name={'image'}
              preview={true}
              filename={values?.image?.name ? values.image.name : values?.image}
              onChange={(event) => {
                setFieldValue('image', event.currentTarget.files[0]);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TourItem
              options={checkpoints?.map((el) => {
                return { value: el.id, label: el.name };
              })}
              values={values}
              errors={errors}
              onRefresh={() => {
                setRefresh(!refresh);
                onRefresh();
              }}
            />
            
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        innerRef={formRef}
        enableReinitialize
        initialValues={tourTmp}
        validateOnMount={true}
        onSubmit={(values) => {
          doSubmit(values);
        }}
      >
        {({ handleSubmit, values, dirty, setFieldValue, errors }) => (
          <CustomModal
            title={
              selectedRow?.id
                ? t('edit_obj', { obj: 'tour' })
                : t('new_obj', { obj: 'tour' })
            }
            content={
              <Form id="form-info-content">
                {renderForm(values, setFieldValue, errors)}
              </Form>
            }
            contentStyle={{ fontSize: '12px' }}
            onCancel={onCancel}
            onOK={() => handleSubmit(values)}
            okButtonText={t('buttons.save')}
            disabledOKButton={!dirty}
            size={'xl'}
            modified={dirty}
          />
        )}
      </Formik>
    </>
  );
};
export default TourDetails;
