import React, { useState, useRef, useCallback, useMemo } from 'react';
import TourAPIs from '../../api/tours';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTableServerSidePagination';

const ToursTable = (props) => {
  const { columns, refresh, selectedTour, language, status } = props;
  const [tours, setTours] = useState([]);
  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current
    if (fetchId === fetchIdRef.current) {
      let queryObj = {};
      if(filters && filters.length > 0){
        filters.forEach(function(filter){
          if(filter?.value) {
            queryObj[filter.id] = filter.value;
          }
        })
      }
      queryObj['status'] = status;
      queryObj['_pagesize'] = pageSize;
      queryObj['_page'] = pageIndex + 1;
      getTours(queryObj);
    }
  }, [refresh, language]);

  const getTours = async (queryObj) => {
    try {
      let res = await TourAPIs.getTours(language, queryObj ? queryObj : null);
      setPageCount(res.headers.total_pages);
      setTours(res.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <CheckboxTablePaginated
        columns={columns}
        data={useMemo(() => tours)}
        total={false}
        filterTable={true}
        pagination={true}
        selectedId={selectedTour?.id}
        checkboxHook={false}
        fetchData={fetchData}
        pageCount={pageCount}
        loading={loading}
      />
    </>
  );
}
export default ToursTable;
