import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CoinsAPI from '../../api/coins';
import APIs from '../../api/apiCalls';
import { CustomTable } from '../../components/ReactTable/ReactTable';
import CoinTypeDetails from './CoinTypeDetails';
import { DeleteModal } from '../../components/Modal';
import { CustomNumberFormatSimple } from '../../components/NumberFormat';
import { withToast } from '../../api/util';
import CoinInstances from './CoinInstances';
import { TitleComponent } from '../../components/TitleComponent';
import CoinTypesTable from './CoinTypesTable';
import { VirtualizedTable } from '../../components/ReactTable/ReactTableVirtualized';

const DigitalCoinTypes = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCoinType, setSelectedCoinType] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const [showCoinDetails, setShowCoinDetails] = useState(false);
  const [showCoinInstances, setShowCoinInstances] = useState(false);
  const { t } = useTranslation();
  const [language, setLanguage] = useState('hu');
  const [refresh, setRefresh] = useState(false);
  const [selectedCampaing, setSelectedCampaing] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      getCampaigns();
    };
    fetchData();
  }, [language]);

  const getCampaigns = async () => {
    try {
      let res = await APIs.getCampaigns(language);
      setCampaigns(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const createCoinType = async (obj) => {
    setShowCoinDetails(false);
    try {
      await withToast(CoinsAPI.createCoinType(obj, language));
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const editCoinType = async (obj) => {
    setShowCoinDetails(false);
    try {
      await withToast(
        CoinsAPI.editCoinType(selectedCoinType.id, obj, language)
      );
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteCoinType = async (id) => {
    setShowDeleteModal(false);
    try {
      await CoinsAPI.deleteCoinType(id);
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const cell = (row) => {
    return <div>{row.value}</div>;
  };

  const cellNum = (row) => {
    return (
      <div className="float-end">
        <CustomNumberFormatSimple
          displayType="text"
          value={row.value}
          integer={true}
        />
      </div>
    );
  };

  const columns = [
    {
      Header: t('name'),
      accessor: 'name',
      Cell: cell,
    },
    {
      Header: t('num_coins'),
      accessor: 'num_coins',
      Cell: cellNum,
    },
    {
      Header: t('quantity_in_use'),
      accessor: 'quantity_in_use',
      Cell: cellNum,
    },
    {
      Header: t('used_coin_instances'),
      accessor: ' ',
      Cell: (row) => {
        if (row.row.isGrouped) return <></>;
        return (
          <div className="text-center">
            <Button
              size="sm"
              className="custom-button"
              onClick={() => {
                setSelectedCoinType(row.row.original),
                  setShowCoinInstances(true);
              }}
            >
              {t('used_coin_instances')}
            </Button>
          </div>
        );
      },
      width: 100,
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: (row) => {
        if (row.row.isGrouped) return <></>;
        return (
          <div className="text-center custom-actions-column">
            <PencilFill
              className="custom-icon me-1"
              color="gray"
              size={16}
              onClick={() => {
                setShowCoinDetails(true), setSelectedCoinType(row.row.original);
              }}
            />
            <X
              className="custom-icon"
              color="red"
              size={20}
              onClick={() => {
                setShowDeleteModal(true), setObjToDelete(row.row.original.id);
              }}
            />
          </div>
        );
      },
      disableFilters: true,
      width: 'auto',
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  const columns1 = [
    {
      Header: t('campaign'),
      accessor: 'name',
    },
  ];

  return (
    <>
      <TitleComponent
        title={t('nft')}
        language={language}
        setLanguage={setLanguage}
      />
      <Button
        size="sm"
        className="mb-2 custom-button"
        onClick={() => {
          setSelectedCoinType(null);
          setShowCoinDetails(true);
        }}
      >
        <span>
          <Plus color="white" size={20} />
        </span>
        {t('buttons.add')}
      </Button>
      <Row>
        <Col lg={2}>
          <div>
            <VirtualizedTable
              columns={columns1}
              data={campaigns}
              onSelect={async(data) => {
                if(data) {
                  setSelectedCampaing(data);
                  setRefresh(!refresh);
                }
              }}
              selectedId={selectedCampaing}
              height={campaigns.length > 20 ? 515 : campaigns.length * 26}
            />
          </div>
        </Col>
        <Col lg={10}>
          {selectedCampaing && (
            <CoinTypesTable
              columns={columns}
              refresh={refresh}
              selectedCoinType={selectedCoinType}
              language={language}
              isPhysical={false}
              selectedCampaing={selectedCampaing}
            />
          )}
        </Col>
      </Row>
      {showCoinInstances && (
        <CoinInstances
          selectedCoinType={selectedCoinType}
          onOK={() => setShowCoinInstances(false)}
          onCancel={() => setShowCoinInstances(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onOK={() => deleteCoinType(objToDelete)}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
      {showCoinDetails && (
        <CoinTypeDetails
          onCancel={() => setShowCoinDetails(false)}
          selectedRow={selectedCoinType}
          onCreate={(obj) => createCoinType(obj)}
          onEdit={(obj) => editCoinType(obj)}
          campaigns={campaigns}
          is_physical={false}
          language={language}
        />
      )}
    </>
  );
};
export default DigitalCoinTypes;
