import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'react-bootstrap';
import { Plus, X } from 'react-bootstrap-icons';
import { FieldArray } from 'formik';
import { FormSelectField } from '../../components/FormField';
import { formikDeleteAndReorderToursArray } from '../Questions/helpers.js'
import { ImageBrowserInline } from '../../components/FileBrowser.jsx';
import CustomModal from '../../components/Modal.js';
import ToursAPI from '../../api/tours.js';
import { withToast } from '../../api/util.js';
import { colorsOptions } from '../constants.js';

const TourItem = (props) => {
  const { values, options, errors, onRefresh } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const { t } = useTranslation();

  const isLastItem = values?.items?.length == 1;

  const deleteTourItem = async (id) => {
    if(isLastItem) {
      setShowDeleteModal(false);
      return;
    }
    try {
      withToast(
        await ToursAPI.deleteTourItem(id)
      );
      setShowDeleteModal(false);
      onRefresh();
    } catch (err) {
      console.error(err);
      setShowDeleteModal(false);
    }
  };

  return (
    <>
      <div>
        <FieldArray
          name="items"
          render={(arrayHelpers) => (
            <div>
              <Form.Label>{t('checkpoints')}</Form.Label>
              <span className="ps-3">
                <Plus
                  title={'add'}
                  className="custom-icon"
                  color="#AF9B78"
                  size={20}
                  onClick={() => {
                    arrayHelpers.push({
                      order: values?.items?.length + 1,
                      checkpoint: null,
                      thumbnail: null,
                      color_code: null
                    });
                  }}
                />{' '}
                {t('new_obj', { obj: 'tour_item' })}
              </span>
              <div className='text-red'>
                {typeof errors.items === 'string' ? errors.items : null}
              </div>
              {values?.items.map((el, index) => {
                return (
                  <div
                    key={index}
                    style={{ backgroundColor: '#EEEEEE' }}
                    className="my-2 p-2"
                  >
                    <Row>
                      <Col lg={3} className="align-self-center">
                        <div className='pe-2 float-start pt-2'>{`${el.order}. `} </div>
                        <FormSelectField
                          noLabel={true}
                          name={`items.${index}.checkpoint`}
                          placeholder={t('select', { obj: 'checkpoint' })}
                          options={options}
                        />
                      </Col>

                      <Col lg={6} className="align-self-center">
                        <ImageBrowserInline
                          name={`items.${index}.thumbnail`}
                          filename={
                            el.thumbnail?.name ? el.thumbnail?.name : el.thumbnail
                          }
                          onChange={(event) => {
                            let elNew;
                            elNew = {
                              ...el,
                              thumbnail: event.currentTarget.files[0]
                            }
                            arrayHelpers.replace(index, elNew);
                          }}
                        />
                      </Col>
                      <Col lg={2} className="align-self-center">
                        <FormSelectField
                          noLabel={true}
                          name={`items.${index}.color_code`}
                          options={colorsOptions(t)}
                          withColor={true}
                        />
                      </Col>
                      <Col lg={1} className="align-self-center">
                        <X
                          className="custom-icon float-end"
                          color="red"
                          size={25}
                          onClick={() => {
                            if(isLastItem) {
                              setShowDeleteModal(true);
                              return;
                            }
                            else {
                              if(el.id) {
                                setShowDeleteModal(true);
                                setObjToDelete(el.id);
                              }
                              else {
                                arrayHelpers.form.setValues(
                                formikDeleteAndReorderToursArray(
                                  arrayHelpers,
                                  index
                                ))
                              }
                            }}
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          )}
        />
      </div>
      {showDeleteModal && (
        <CustomModal
          title={t('buttons.delete')}
          onOK={() => deleteTourItem(objToDelete)}
          onCancel={() => setShowDeleteModal(false)}
          okButtonText={t('buttons.ok')}
          hideCancelButton={isLastItem ? true : false}
          dialogClassName={"second-dialog"}
          content={
            <>
              {isLastItem
                ? t('cantDeleteLastImage')
                : t('messages.want_to_delete')
              }
            </>
          }
        />
      )}
    </>
  );
}
export default TourItem;
