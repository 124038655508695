import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col } from 'react-bootstrap';
import APIs from '../../api/apiCalls';
import CustomModal from '../../components/Modal';
import { FormCheckbox, FormSelectField, FormTextField } from '../../components/FormField';
import yup from "../../yupUtils";
import { Formik } from 'formik';
import { ImageBrowser } from '../../components/FileBrowser';
import { X } from 'react-bootstrap-icons';
import { colorsOptions } from '../constants';

const CategoryDetails = (props) => {
  const { selectedRow, onCancel, onCreate, onEdit, language } = props;
  const [questionCategoryTmp, setQuestionCategortTmp] = useState(null);
  const { t } = useTranslation();
  const formRef = useRef();

  const initialState = {
    name: '',
    is_hidden: false,
    editable: true,
    picture: ''
  }

  const schema = yup.object({
    name: yup.string().required()
  })

  useEffect(() => {
    const setData = async () => {
      if(selectedRow?.id) {
        setQuestionCategortTmp(await getQuestionCategoryById(selectedRow?.id))
      }
      else {
        setQuestionCategortTmp(initialState)
      }
    };
    setData();
  }, [selectedRow])


  const getQuestionCategoryById = async(id) =>  {
    try {
      let res = await APIs.getQuestionCategoryById(id, language);
      return res.data
    } catch (err) {
      console.error(err);
    }
  }

  const doSubmit = (values) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      if(values?.id) {
        if(!values.picture) delete values.picture;
        onEdit(values)
      }
      else {
        onCreate(values)
      }
    }
  }

  const renderForm = (values, setFieldValue) => {
    return(
      <>
        <Row>
          <FormTextField
            title={t('name')}
            name={"name"}
          />
        </Row>
        <Row>
          <FormCheckbox
            title={t('is_hidden')}
            label={t('is_hidden')}
            name={"is_hidden"}
          />
        </Row>
        <Row>
          <Col lg={8}>
            <ImageBrowser
              title={t('picture')}
              name={"picture"}
              preview={true}
              filename={values?.picture?.name ? values.picture.name : values?.picture}
              onChange={(event) => {
                setFieldValue("picture", event.currentTarget.files[0]);
              }}
            />
          </Col>
          <Col lg={1} style={{marginTop: "25px"}}>
            {values?.picture && <X className="custom-icon" color="red" size={20}
              onClick={() => setFieldValue("picture", '')}/>}
          </Col>
          <Col lg={3}>
            <FormSelectField
              name="color_code"
              title={t('color_code')}
              options={colorsOptions(t)}
              withColor={true}
            />
          </Col>
        </Row>
        <Row>

        </Row>
      </>
    )
  }

  return(
    <Formik
        validationSchema={schema}
        innerRef={formRef}
        enableReinitialize
        initialValues={questionCategoryTmp}
        validateOnMount={true}
        onSubmit={(values) => {
          doSubmit(values)
        }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          dirty,
        }) => (
        <>
          <CustomModal
            title={selectedRow?.id ? t('edit_obj', {obj: 'question_category'}) : t('new_obj', {obj: 'question_category'})}
            content={
              <Form id="form-info-content">
                {renderForm(values, setFieldValue)}
              </Form>
            }
            contentStyle={{fontSize: "12px"}}
            onCancel={onCancel}
            onOK={() => handleSubmit()}
            okButtonText={t('buttons.save')}
            disabledOKButton={!dirty}
            modified={dirty}
          />
        </>
      )}
    </Formik>
  )
}
export default CategoryDetails;
