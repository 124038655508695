import { useTable, useFlexLayout } from 'react-table';
import React, { useEffect, useMemo, useRef } from 'react';
import { VariableSizeList } from 'react-window';

export const scrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const defaultPropGetter = () => ({});

export const Table = (props) => {
  const {
    columns,
    data,
    height,
    trClick,
    getRowProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    selectedId,
  } = props;
  const tableContainerRef = useRef(null);
  const scrollBarSize = useMemo(() => scrollbarWidth(), []);
  const listRef = useRef(null);
  const rowHeights = useRef({});

  const defaultColumn = useMemo(
    () => ({
      width: 100,
      minWidth: 20,
      maxWidth: 200,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data: data,
        columns,
        defaultColumn,
      },
      useFlexLayout
    );

  const RenderRow = ({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    let st = {
      ...style,
      backgroundColor: row.original?.id === selectedId ? '#f7f6f3' : null,
    };
    const rowRef = useRef(null);

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);

    return (
      <div
        {...row.getRowProps(getRowProps(row))}
        style={st}
        className="tr-div"
        onClick={(e) => trClick(e, row.original)}
      >
        <div className="tr" ref={rowRef}>
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps([
                  getColumnProps(cell.column),
                  getCellProps(cell),
                ])}
                className="td"
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const getRowHeight = (index) => {
    return rowHeights.current[index] + 8 || 82;
  };

  function setRowHeight(index, size) {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    }
  }

  return (
    <>
      <div className="virtualized-table" ref={tableContainerRef}>
        <div {...getTableProps()} className="table">
          <div className="thead">
            {headerGroups.map((headerGroup) => {
              return (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                  style={{ width: `calc(100% - ${scrollBarSize}px` }}
                >
                  {headerGroup.headers.map((column) => {
                    return (
                      <div {...column.getHeaderProps()} className="th">
                        <div>{column.render('Header')}</div>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <VariableSizeList
            ref={listRef}
            height={height || 800}
            itemCount={rows.length}
            width="100%"
            itemSize={getRowHeight}
            innerElementType={({ children, style, ...rest }) => (
              <>
                <div className="tbody">
                  <div {...getTableBodyProps()} {...rest} style={style}>
                    {children}
                  </div>
                </div>
              </>
            )}
          >
            {RenderRow}
          </VariableSizeList>
        </div>
      </div>
    </>
  );
};

export const VirtualizedTable = ({
  columns,
  data,
  selectedId,
  onSelect,
  showChangeRowModal,
  setChangeRowId,
  height,
  isModified,
  ...props
}) => {
  return (
    <Table
      data={data}
      columns={columns}
      trClick={(e, rowData) => {
        e.preventDefault();
        if (e.target.tagName === 'svg') return;
        onSelect(rowData.id ? rowData.id : rowData.uuid);
      }}
      getCellProps={(cellInfo) => {
        if (cellInfo.row.isGrouped)
          return {
            style: {
              backgroundColor: 'initial',
            },
          };
        else if (cellInfo.column.id === ' ')
          return {
            style: {
              backgroundColor:
                cellInfo.row.original?.id === selectedId
                  ? '#f7f6f3'
                  : 'initial',
            },
          };
        else
          return {
            onClick: () => {
              if (cellInfo.column.id === 'actions') return;
              if (cellInfo) onSelect(cellInfo.row.original.id);
            },
            style: {
              textAlign: cellInfo.column.id === 'actions' ? 'center' : null,
              backgroundColor:
                cellInfo.row.original?.id === selectedId
                  ? '#f7f6f3'
                  : 'initial',
            },
          };
      }}
      getRowProps={(row) => {
        if (row.original?.id === selectedId) {
          return {
            style: {
              backgroundColor: '#f7f6f3',
            },
          };
        }
      }}
      height={height}
      selectedId={selectedId}
      {...props}
    />
  );
};
