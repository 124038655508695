import RestHelper from "./api";
import { endpoints } from "./endpoints";
import i18next from 'i18next';
import { jsonToFormData } from './util';
import axios from 'axios';

class Tours {

  //Tours
  async getTours(language, queryObj) {
    const endpoint = endpoints.get_tours + language;
    const res = await RestHelper.get(endpoint, queryObj);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async getTourById(id, language) {
    const endpoint = endpoints.get_tour + id + '/' + language;
    const res = await RestHelper.get(endpoint);
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.data_could_not_requested'));
    }
    else {
      return res
    }
  }

  async createTour(obj, language) {
    const endpoint = endpoints.create_tour + language;
    const res = await RestHelper.post(endpoint, jsonToFormData(obj), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (res.status !== 201) {
      throw new Error(i18next.t('messages.obj_could_not_be_created', { obj: 'tour'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_created', { obj: 'tour'})
      })
    }
  }

  async editTour(id, obj, language) {
    const endpoint = endpoints.edit_tour + id + '/' + language;
    const res = await RestHelper.put(endpoint, jsonToFormData(obj), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    
    if (res.status !== 200) {
      throw new Error(i18next.t('messages.obj_could_not_be_modified', { obj: 'tour'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_modified', { obj: 'tour'})
      })
    }
  }

  async deleteTour(id) {
    const endpoint = endpoints.delete_tour + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'tour'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'tour'})
      })
    }
  }

  async bulkDeleteTour(id) {
    const endpoint = endpoints.bulk_delete_tour;
    const res = await RestHelper.post(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'tour'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'tour'})
      })
    }
  }

  async deleteTourItem(id) {
    const endpoint = endpoints.delete_tour_item + id;
    const res = await RestHelper.delete(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'tour_item'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'tour_item'})
      })
    }
  }


  async deleteTourImage(id) {
    const endpoint = endpoints.delete_tour_image + id + '/delete_image' ;
    const res = await RestHelper.post(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_deleted', { obj: 'picture'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_deleted', { obj: 'picture'})
      })
    }
  }

  async exportTours() {
    const endpoint = endpoints.export_tours ;
    const res = await RestHelper.post(endpoint);
    if (res.status !== 204) {
      throw new Error(i18next.t('messages.obj_could_not_be_exported', { obj: 'tour'}))
    }
    else {
      return ({
        status:res.status,
        data: res.data,
        message: i18next.t('messages.obj_exported', { obj: 'tour'})
      })
    }
  }

}

export default new Tours();
