import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { useAuth } from '../../LoginContext';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import APIs from '../../api/apiCalls';
import { ChevronDown } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';

const Navbar = (props) => {
  const { currentUser, setCurrentUser } = useAuth();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const lng = localStorage.getItem('language');
    i18n.changeLanguage(lng ? lng : 'hu');
  }, []);

  const handleLogout = async () => {
    try {
      //localStorage.setItem('user', null)
      await APIs.logout({});
      axios.defaults.headers.common['Authorization'] = null;
      localStorage.removeItem('user');
    } catch (err) {
      console.error(err);
    } finally {
      //await cookie.remove('bearer');
      await setCurrentUser(null);
      //await clearState();
    }
  };

  const quizzesMenu = [
    '/campaigns',
    '/quizzes',
    '/questionbanks',
    '/agegroups',
    '/raffles',
  ];

  const menuAudioGuide = ['/checkpoints', '/tours'];

  const coinsMenu = ['/digitalcoins', '/physicalcoins', '/themes', '/imprints'];

  return (
    <div className="menu-wr">
      <div className="navmenu">
        <ul>
          {currentUser ? (
            <>
              <li>
                <p
                  className={
                    quizzesMenu.includes(location.pathname)
                      ? 'menu-active'
                      : null
                  }
                >
                  {t('quizzes')}
                  <span className="submenu-chevron">
                    <ChevronDown fontSize={15} />
                  </span>
                </p>
                <ul>
                  <li>
                    {
                      <a href="#/campaigns" onClick={() => {}}>
                        <span>{t('campaigns')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/quizzes" onClick={() => {}}>
                        <span>{t('quizzes')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/questionbanks" onClick={() => {}}>
                        <span>{t('question_banks')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/agegroups" onClick={() => {}}>
                        <span>{t('age_groups')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/raffles" onClick={() => {}}>
                        <span>{t('raffles')}</span>
                      </a>
                    }
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  {
                    <a
                      href="#/categories"
                      onClick={() => {}}
                      className={
                        location.pathname === '/categories'
                          ? 'menu-active'
                          : null
                      }
                    >
                      <span>{t('question_categories')}</span>
                    </a>
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    <a
                      href="#/infocontent"
                      onClick={() => {}}
                      className={
                        location.pathname === '/infocontent'
                          ? 'menu-active'
                          : null
                      }
                    >
                      <span>{t('info_content')}</span>
                    </a>
                  }
                </p>
                {/* <p>{t('info_content')}</p> */}
              </li>
              <li>
                <p
                  className={
                    coinsMenu.includes(location.pathname) ? 'menu-active' : null
                  }
                >
                  {t('coins')}
                  <span className="submenu-chevron">
                    <ChevronDown fontSize={15} />
                  </span>
                </p>
                <ul>
                  <li>
                    {
                      <a href="#/digitalcoins" onClick={() => {}}>
                        <span>{t('nft')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/physicalcoins" onClick={() => {}}>
                        <span>{t('physical_coins')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/themes" onClick={() => {}}>
                        <span>{t('themes')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/imprints" onClick={() => {}}>
                        <span>{t('imprint_instances')}</span>
                      </a>
                    }
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  {
                    <a
                      href="#/transactions"
                      onClick={() => {}}
                      className={
                        location.pathname === '/transactions'
                          ? 'menu-active'
                          : null
                      }
                    >
                      <span>{t('transaction_administration')}</span>
                    </a>
                  }
                </p>
              </li>
              <li>
                <p
                  className={
                    menuAudioGuide.includes(location.pathname)
                      ? 'menu-active'
                      : null
                  }
                >
                  {t('audioguide')}
                  <span className="submenu-chevron">
                    <ChevronDown fontSize={15} />
                  </span>
                </p>
                <ul>
                  <li>
                    {
                      <a href="#/checkpoints" onClick={() => {}}>
                        <span>{t('checkpoints')}</span>
                      </a>
                    }
                  </li>
                  <li>
                    {
                      <a href="#/tours" onClick={() => {}}>
                        <span>{t('tours')}</span>
                      </a>
                    }
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  {
                    <a
                      href="#/statistics"
                      onClick={() => {}}
                      className={
                        location.pathname === '/statistics'
                          ? 'menu-active'
                          : null
                      }
                    >
                      <span>{t('statistics')}</span>
                    </a>
                  }
                </p>
              </li>
              <li>
                <p
                  className={
                    location.pathname === '/users' ? 'menu-active' : null
                  }
                >
                  {
                    <a
                      href="#/users"
                      onClick={() => {}}
                      className={
                        location.pathname === '/users' ? 'menu-active' : null
                      }
                    >
                      <span>{t('users')}</span>
                    </a>
                  }
                </p>
              </li>
              <li>
                <a
                  style={{ marginBottom: '1rem' }}
                  href="#/iconicledger"
                  onClick={() => {}}
                  className={
                    location.pathname === '/iconicledger' ? 'menu-active' : null
                  }
                >
                  {t('iconicledger')}
                </a>
              </li>
              <li>
                <a
                  style={{ marginBottom: '1rem', cursor: 'pointer' }}
                  onClick={() => handleLogout()}
                >
                  {t('logout')}
                </a>
              </li>
            </>
          ) : (
            <li>
              <a style={{ marginBottom: '1rem' }} href="#/" onClick={() => {}}>
                {t('login')}
              </a>
            </li>
          )}

          <li>
            <a style={{ marginTop: '-4px' }}>
              <LanguageSwitcher />
            </a>
          </li>
        </ul>
        {currentUser && (
          <>
            <div className="align-self-center" style={{ marginBottom: '1rem' }}>
              {t('user')}: {currentUser.username}
            </div>

            <div
              className="align-self-center"
              style={{ marginBottom: '1rem', marginLeft: '10px' }}
            >
              {t('version')}: {currentUser?.version}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default withTranslation()(Navbar);
