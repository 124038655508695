import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navbar from './containers/Navbar';
import Questions from './containers/Questions';
import QuestionBanks from './containers/QuestionBanks';
import Login from './containers/LoginPage';
import { PrivateRoute } from './components/PrivateRoute';
import { useAuth } from './LoginContext';
import CustomToasterContainer from './components/CustomToasterContainer';
import Categories from './containers/Categories';
import AgeGroups from './containers/AgeGroups';
import Quizzes from './containers/Quizzes';
import Users from './containers/Users';
import IconicLedger from './containers/IconicLedger';
import Campaigns from './containers/Campaigns';
import InfoContent from './containers/InfoContent';
import Transactions from './containers/Transactions';
import Raffles from './containers/Raffles';
import Statistics from './containers/Statistics';
import ResetPassword from './containers/ResetPassword';
import APIs from './api/apiCalls';
import { useTranslation } from "react-i18next";
import Themes from './containers/Themes';
import Imprints from './containers/Coins/Imprints';
import PhysicalCoinTypes from './containers/Coins/PhysicalCoinTypes';
import DigitalCoinTypes from './containers/Coins/DigitalCoinTypes';
import Checkpoints from './containers/Checkpoints';
import Tours from './containers/Tours';

const App = () => {

  const { currentUser } = useAuth();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const setLanguageBE = async () => {

      let initLanguage = localStorage.getItem("language");
      if(!initLanguage) {
        initLanguage = navigator.language;

        await APIs.setLanguage({language: initLanguage});
        if(initLanguage === 'hu') {
          i18n.changeLanguage('hu');
          localStorage.setItem("language", 'hu');
        }
        else {
          i18n.changeLanguage('en');
          localStorage.setItem("language", 'en');
        }
      }
    }
    setLanguageBE();
  }, [])

  return (
    <div id="app">
      { location.pathname !== "/reset_password" && <Navbar /> }

      <div id="mainContainer">
        <Routes>
          <Route exact path="/" element={
            currentUser
            ? <Navigate to="/statistics" />
            : <Login />}
          />
          
          <Route path="/reset_password" element={<ResetPassword/>} />
          <Route
            path="/campaigns"
            element={<PrivateRoute> <Campaigns /> </PrivateRoute>}
          />
          <Route
            path="/quizzes"
            element={<PrivateRoute> <Quizzes /> </PrivateRoute>}
          />
          <Route
            path="/questionbanks"
            element={<PrivateRoute> <QuestionBanks /> </PrivateRoute>}
          />
          <Route
            path="/categories"
            element={<PrivateRoute> <Categories /> </PrivateRoute>}
          />
          <Route
            path="/agegroups"
            element={<PrivateRoute> <AgeGroups /> </PrivateRoute>}
          />
          <Route
            path="/users"
            element={<PrivateRoute> <Users /> </PrivateRoute>}
          />
          <Route
            path="/iconicledger"
            element={<PrivateRoute> <IconicLedger/> </PrivateRoute>}
          />
          <Route
            path="/infocontent"
            element={<PrivateRoute> <InfoContent /> </PrivateRoute>}
          />
          <Route
            path="/transactions"
            element={<PrivateRoute> <Transactions /> </PrivateRoute>}
          />
          <Route
            path="/raffles"
            element={<PrivateRoute> <Raffles /> </PrivateRoute>}
          />
          <Route
            path="/statistics"
            element={<PrivateRoute> <Statistics /> </PrivateRoute>}
          />
          <Route
            path="/themes"
            element={<PrivateRoute> <Themes /> </PrivateRoute>}
          />
          <Route
            path="/imprints"
            element={<PrivateRoute> <Imprints /> </PrivateRoute>}
          />
          <Route
            path="/physicalcoins"
            element={<PrivateRoute> <PhysicalCoinTypes /> </PrivateRoute>}
          />
          <Route
            path="/digitalcoins"
            element={<PrivateRoute> <DigitalCoinTypes /> </PrivateRoute>}
          />
          <Route
            path="/checkpoints"
            element={<PrivateRoute> <Checkpoints /> </PrivateRoute>}
          />
          <Route
            path="/tours"
            element={<PrivateRoute> <Tours /> </PrivateRoute>}
          />
          <Route path="/">
            <Route 
              path="*" 
              element={<PrivateRoute> <Navigate replace to="/" /> </PrivateRoute>}
            />
          </Route>
          {/* <Route
            path="*"
            element={<PrivateRoute> <Navigate replace to="/" /> </PrivateRoute>}
          /> */}
          {/* <Route path="/quizzes" element={<Quizzes/>} />
          <Route path="/questionbanks" element={<QuestionBanks/>} /> */}
          {/* <Route path="/categories" element={<Categories/>} />
          <Route path="/agegroups" element={<AgeGroups/>} />
          <Route path="/users" element={<Users/>} /> */}
          {/* <Route path="/iconicledger" element={<IconicLedger/>} />
          <Route path="/infocontent" element={<InfoContent/>} />
          <Route path="/transactions" element={<Transactions/>} />
          <Route path="/raffles" element={<Raffles/>} />
          <Route path="/statistics" element={<Statistics/>} />
          <Route path="/themes" element={<Themes/>} /> */}
          {/* <Route path="/imprints" element={<Imprints/>} />
          <Route path="/physicalcoins" element={<PhysicalCoinTypes/>} />
          <Route path="/digitalcoins" element={<DigitalCoinTypes/>} />
          <Route path="/checkpoints" element={<Checkpoints/>} />
          <Route path="/tours" element={<Tours/>} />
          <Route path="*" element={<Navigate replace to="/" />} /> */}
        </Routes>
        <CustomToasterContainer />
      </div>
    </div>
  );
}

export default App;
