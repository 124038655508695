import React, { useState, useRef, useCallback, useMemo } from 'react';
import CoinsAPI from '../../api/coins';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTableServerSidePagination';

const CoinTypesTable = (props) => {
  const {
    columns,
    refresh,
    selectedCoinType,
    language,
    status,
    isPhysical,
    checkboxHook,
    onSelectedRowsChange,
    filterTable,
    initialState,
    selectedCampaing
  } = props;
  const [coinTypes, setCoinTypes] = useState([]);
  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = useCallback(
    ({ pageIndex, pageSize, filters }) => {
      setLoading(true);
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        let queryObj = {};
        if (filters && filters.length > 0) {
          filters.forEach(function (filter) {
            if (filter?.value) {
              queryObj[filter.id] = filter.value;
            }
          });
        }
        if(selectedCampaing) {
          queryObj['campaign'] = selectedCampaing;
        }
        queryObj['physical'] = isPhysical ? 1 : 0;
        queryObj['status'] = status;
        queryObj['_pagesize'] = pageSize;
        queryObj['_page'] = pageIndex + 1;
        getCoinTypes(queryObj);
      }
    },
    [refresh, language]
  );

  const getCoinTypes = async (obj) => {
    try {
      let res = await CoinsAPI.getCoinTypes(obj, language);
      setPageCount(res.headers.total_pages);
      setTotalItems(res.headers.total_items);
      setCoinTypes(res.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <CheckboxTablePaginated
        columns={columns}
        data={useMemo(() => coinTypes)}
        total={false}
        filterTable={filterTable ? filterTable : false}
        pagination={true}
        selectedId={selectedCoinType?.id}
        checkboxHook={checkboxHook ? checkboxHook : false}
        fetchData={fetchData}
        pageCount={pageCount}
        loading={loading}
        onSelectedRowsChange={(rows, isAll) => {
          if(onSelectedRowsChange) {
            onSelectedRowsChange(rows, isAll, totalItems)
          }
        }}
        initialState={initialState}
      />
    </>
  );
};
export default CoinTypesTable;
