import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Button, Row,Tabs, Tab } from 'react-bootstrap';
import CustomModal, { DeleteModal } from '../../components/Modal';
import { FormCheckbox, FormDatePickerField, FormNumber, FormSelectField, FormTextField } from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from "yup";
import moment from 'moment';
import { Plus } from 'react-bootstrap-icons';
import AddImage from './AddImage';
import CoinsAPI from '../../api/coins';
import DisplayImages from './DisplayImages';
import { materialOptions, purityOptions, qualityOptions } from '../constants';

const CoinTypeDetails = (props) => {
  const { selectedRow, onCancel, onCreate, onEdit,
    campaigns, themes, is_physical, language } = props;
  const [coinTmp, setCoinTmp] = useState(null);
  const [addImageModal, setAddImageModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const formRef = useRef()

  const initialState = {
    status: '',
    name: '',
    nominal_value: '',
    series: '',
    date_of_issue: moment(Date.now()).format('YYYY-MM-DD'),
    is_physical: is_physical,
    designer_name: '',
    issuer: '',
    carbon_footprint: 0.0,
    description: '',
    info_link: '',
    material: '',
    purity: '',
    diameter: '',
    weight: '',
    edge: '',
    num_coins: '',
    campaign: '',
    type_digital: is_physical ? '' : 'in_quiz',
    type_physical: '',
    order: 1,
    short_name: '',
    quality: '',
  }

  const schema = yup.object({
    status: yup.string().required(t('validation.is_required', {obj: t('status')})),
    is_physical: yup.boolean(),
    name: yup.string().required(t('validation.is_required', {obj: t('name')})),
    short_name: yup.string(),
    campaign: yup.string().when('is_physical', {
      is: false,
      then: schema => schema.required(t('validation.is_required', {obj: t('campaign')})),
      otherwise: schema => schema.optional()
    }),
    series: yup.string().when('is_physical', {
      is: false,
      then: schema => schema.required(t('validation.is_required', {obj: t('series')})),
      otherwise: schema => schema.optional()
    }),
    order: yup.number().when('is_physical', {
      is: false,
      then: schema => schema.required(t('validation.is_required', {obj: t('order')})),
      otherwise: schema => schema.optional()
    }),
    quality: yup.string().when('is_physical', {
      is: true,
      then: schema => schema.required(t('validation.is_required', {obj: t('quality')})),
      otherwise: schema => schema.optional()
    }),
    type_physical: yup.string().when('is_physical', {
      is: true,
      then: schema => schema.required(t('validation.is_required', {obj: t('type_physical')})),
      otherwise: schema => schema.optional()
    }),
    type_digital: yup.string().nullable(),
    nominal_value: yup.number().required(t('validation.is_required', {obj: t('nominal_value')})),
    date_of_issue: yup.date().required(t('validation.is_required', {obj: t('date_of_issue')})),
    issuer: yup.string().required(t('validation.is_required', {obj: t('issuer')})),
    num_coins: yup.number().required(t('validation.is_required', {obj: t('num_coins')})),
    description: yup.string().required(t('validation.is_required', {obj: t('description')})),
    info_link: yup.string().url(t('validation.valid_url')),
    carbon_footprint: yup.string().nullable(),
    designer_name: yup.string().when('is_physical', {
      is: true,
      then: schema => schema.required(t('validation.is_required', {obj: t('designer_name')})),
      otherwise: schema => schema.optional()
    }),
    material: yup.string().nullable(),
    purity: yup.string().nullable(),
    diameter: yup.string().nullable(),
    weight: yup.number().nullable(),
    edge: yup.string().nullable(),
  });

  useEffect(() => {
    const setData = async () => {
      if(selectedRow?.id) {
          const coin = await getCoinTypeById(selectedRow?.id)
          coin.campaign = coin?.campaign?.id;
          coin.purity = coin?.purity ? coin?.purity : '';
          coin.diameter = coin?.diameter ? coin?.diameter : '';
          setCoinTmp(coin);
          setRefresh(false);
        }
        else {
          setCoinTmp(initialState)
        }
      };
    setData();
  }, [selectedRow?.id, refresh])

  const getCoinTypeById = async(id) =>  {
    try {
      let res = await CoinsAPI.getCoinTypeById(id, language);
      return res.data
    } catch (err) {
      console.error(err);
    }
  }

  const doSubmit = (values) => {
    //formRef.current.handleSubmit();
    if (formRef.current.isValid) {
      if(coinTmp?.id) {
        onEdit(getObjectToSave(values))
      }
      else {
        onCreate(getObjectToSave(values))
      }
    }
  }

  const getObjectToSave = (values) => {
    const coin = values;
    coin.campaign = values.campaign?.id ? values.campaign?.id : values.campaign;
    coin.weight = values.weight === undefined ? null : values.weight;
    return coin
  }

  const setPurityOptions = (purity) => {
    const doesExist = purityOptions(t).some(el => el.value === purity)
    if(doesExist) return purityOptions(t);
    return [
      {label: purity, value: purity},
      ...purityOptions(t)
    ]
  } 

  const renderCoins = (values) => {
    return (
      <>
        <Row>
          <Col lg={6}>
            <FormSelectField
              name="status"
              title={t('status')}
              options={[
                {label: t('active'), value: 'active'},
                {label: t('development'), value: 'development'},
                {label: t('testing'), value: 'testing'},
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormTextField
              title={t('name')}
              name={"name"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {values?.is_physical ?
              <FormSelectField
                name="type_physical"
                title={t('type_physical')}
                options={[
                  {label: t('regular'), value: 'regular'},
                  {label: t('commemorative'), value: 'commemorative'},
                  {label: t('regular_commemorative'), value: 'regular_commemorative'},
                ]}
              />:
              <FormTextField
                title={t('short_name')}
                name={"short_name"}
              />
            }
          </Col>
          <Col>
            {values?.is_physical ?
              <FormSelectField
                name="quality"
                title={t('quality')}
                options={qualityOptions(t)}
              /> :
              <FormNumber
                title={t('order')}
                name={"order"}
              />
            }
          </Col>
          <Col>
            <FormTextField
              title={t('series')}
              name={"series"}
            />
          </Col>
          {!values?.is_physical &&
          <Col>
            <FormSelectField
              name="campaign"
              title={t('campaign')}
              options={[{label: t('choose'), value: ''}, ...campaigns?.map(el => ({value: el.id, label: el.name}))]}
            />
          </Col>
          }
        </Row>
        <Row>
          {!values?.is_physical && <Col>
            <FormSelectField
              name="type_digital"
              title={t('type_digital')}
              options={[
                {label: t('in_quiz'), value: 'in_quiz'},
                //{label: t('in_campaign'), value: 'in_campaign'},
                //{label: t('special'), value: 'special'},
              ]}
            />
          </Col>}
          <Col>
            <FormNumber
             title={t('nominal_value')}
             name={"nominal_value"}
            />
          </Col>
          <Col lg={6}>
            <FormDatePickerField
              title={t('date_of_issue')}
              name={"date_of_issue"}
              dateFormat="yyyy-MM-dd"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormTextField
              title={t('issuer')}
              name={"issuer"}
            />
          </Col>
          <Col>
            <FormTextField
              title={t('designer_name')}
              name={"designer_name"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
             <FormTextField
              as="textarea"
              rows={10}
              title={t('description')}
              name={"description"}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormTextField
              title={t('info_link')}
              name={"info_link"}
            />
          </Col>
          <Col>
          </Col>
        </Row>
        {values?.is_physical && <Row>
          <Col>
            <FormSelectField
              title={t('themes')}
              name={"themes"}
              id={"themes"}
              options={themes?.map(el => {return({value: el.id, label: el.name})})}
              isMulti={true}
            />
          </Col>
        </Row>}
        <Row>
          <Col>
            <FormNumber
              title={t('num_coins')}
              name={"num_coins"}
            />
          </Col>
          <Col>
          {!values?.is_physical &&
            <FormNumber
              title={t('carbon_footprint')}
              name={"carbon_footprint"}
              isDecimal={true}
            />
          }
          </Col>
        </Row>
        {(values?.is_physical) && <>
          <Row>
            <Col>
            <FormSelectField
              name="material"
              title={t('material')}
              options={materialOptions(t)}
            />
            </Col>
            <Col>
              <FormSelectField
                name="purity"
                title={t('purity')}
                creatable={true}
                options={setPurityOptions(values?.purity)}
              />
            </Col>
            <Col>
              <FormTextField
                title={t('diameter')}
                name={"diameter"}
              />
            </Col>
            <Col>
              <FormNumber
              title={t('weight')}
              name={"weight"}
              isDecimal={true}
              decimalScale={3}
              />
            </Col>
            <Col>
              <FormTextField
                title={t('edge')}
                name={"edge"}
              />
            </Col>
          </Row>
        </>}
        {coinTmp?.id ?
        <>
          <Form.Label>{t('pictures')}</Form.Label>
          <div>
            <Button size="sm" className="mb-2 custom-button" onClick={()=>{setAddImageModal(true)}}>
              <span><Plus color="white" size={20}/></span>{t('buttons.add')}
            </Button>
          </div>
          <DisplayImages
            images={coinTmp?.pictures}
            onRefresh={() => setRefresh(true)}
          /> </> :
          <div className="mt-2">{t('save_coin_type_first')}</div>
        }
      </>
    )
  }

  return(
    <>
    <Formik
      validationSchema={schema}
      innerRef={formRef}
      enableReinitialize
      initialValues={coinTmp}
      validateOnMount={true}
      onSubmit={(values) => {
        doSubmit(values)
      }}
    >
      {({
        handleSubmit,
        values,
        dirty,
        setFieldValue,
      }) => (
      <>
       <Form id="form-content">
        <CustomModal
          title={selectedRow?.id ? t('edit_obj', {obj: 'coin'}) : t('new_obj', {obj: 'coin'})}
          content={renderCoins(values, setFieldValue)}
          contentStyle={{fontSize: "12px"}}
          onCancel={onCancel}
          onOK={() => handleSubmit(values)}
          okButtonText={t('buttons.save')}
          disabledOKButton={!dirty}
          modified={dirty}
          size={'xl'}
        />
      </Form>
      </>
    )}
    </Formik>
    {addImageModal && <AddImage
      onCancel={() => setAddImageModal(false)}
      onRefresh={(val) => setRefresh(true)}
      selectedCoin={coinTmp}
      language={language}
    />}
    </>
  )

}
export default CoinTypeDetails;
