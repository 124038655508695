import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import CustomModal from '../../components/Modal';
import CheckpointsAPI from '../../api/checkpoints';
import { withToast } from '../../api/util';
import { X, Check } from 'react-bootstrap-icons';

const DisplayImages = (props) => {
  const { images, onRefresh, selectedCheckpoint, onDeleted, onChangeDefaultImage, defaultImage } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const { t } = useTranslation();

  const deleteCheckpointImage = async (name) => {
    if(isLastImage) {
      setShowDeleteModal(false);
      return;
    }
    try {
      withToast(
        await CheckpointsAPI.deleteCheckpointImage(selectedCheckpoint.id, {
          images_name_to_delete: [name],
        })
      );
      onRefresh();
      setShowDeleteModal(false);
    } catch (err) {
      console.error(err);
      setShowDeleteModal(false);
    }
  };

  const isLastImage = selectedCheckpoint?.id && images?.length == 1;
  const isUnsavedImages = images?.filter(el => el.file)?.length > 0;
  return (
    <Row>
      {images?.map((image, i) => {
        const isMainImage = image === defaultImage?.image;
        return (
          <Col lg={4} key={i} className='mt-1'>
            <>
              {/* <div>{image.file?.name ? image.file.name : image}</div> */}
              <div
                style={{
                  backgroundColor: '#EEEEEE',
                  padding: '5px',
                  marginBottom: '5px',
                }}
              >
                <X
                  className={
                    isUnsavedImages && !image.file
                      ? 'd-none'
                      : 'custom-icon float-end'
                  }
                  color="red"
                  size={20}
                  onClick={() => {
                    if (image.file) {
                      onDeleted(image);
                    } else {
                      setShowDeleteModal(true);
                      setObjToDelete(image);
                    }
                  }}
                />
                 {isMainImage ?
                  <div>
                    <Check color="green" size={20} /> {t('firstImage')}
                  </div>
                 :<div style={{height: '20px'}}>
                    <a
                      className="custom-icon"
                      type="radio"
                      name="radio-group-image"
                      onClick={(e) => {
                        e.preventDefault();
                        onChangeDefaultImage({
                          image: image,
                          index: i
                        });
                      }}>
                      {t('setAsFirstImage')}
                    </a>
                  </div>
                }
                <div
                  style={{
                    width: '100%',
                    height: '20vh',
                    marginTop: '20px',
                    marginBottom: '10px',
                  }}
                >
                  <img
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                    src={image.display ? image.display : image}
                  />
                </div>
              </div>
            </>
          </Col>
        );
      })}
      {showDeleteModal && (
        <CustomModal
          title={t('buttons.delete')}
          onOK={() => deleteCheckpointImage(objToDelete)}
          onCancel={() => setShowDeleteModal(false)}
          okButtonText={t('buttons.ok')}
          hideCancelButton={isLastImage ? true : false}
          dialogClassName={"second-dialog"}
          content={
            <>
              {isLastImage
                ? t('cantDeleteLastImage')
                : t('messages.want_to_delete')
              }
            </>
          }
        />
      )}
    </Row>
  );
};
export default DisplayImages;
