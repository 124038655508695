import React from 'react';
import { SelectSimple } from '../../components/SelectReact';
import { useTranslation } from "react-i18next";
import { CustomNumberFormatSimple } from '../../components/NumberFormat';
import { DatePickerSimple } from '../DatePicker';

 // Define a default UI for filtering
export const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
  }) => {
  return (
     <input
      type="text"
      className="form-control custom-form-control"
      value={filterValue || ''}
      onChange={(e) =>  setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely}
    />
  )
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if(row.values[id])
      options.add({label: row.values[id], value: row.values[id]});
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <SelectSimple
      id='custom-select'
      options={options}
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder=''
      menuPortalTarget={document.body}
      noFeedback={true}
    />
  );
};

export const TrueFalseColumnFilter = ({
  column: { filterValue, setFilter },
}) => {
  const { t } = useTranslation();
  return (
    <SelectSimple
      id='custom-select'
      options={[
        {label: t('all'), value: ''},
        {label: t('external_yes'), value: 'true'},
        {label: t('external_no'), value: 'false'}
      ]}
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder=''
      menuPortalTarget={document.body}
      noFeedback={true}
    />
  );
};

export const SelectWithOptionsColumnFilter = ({
  column: { filterValue, setFilter, selectOptions },
}) => {
  return (
    <SelectSimple
      id='custom-select'
      options={selectOptions}
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder=''
      menuPortalTarget={document.body}
      noFeedback={true}
    />
  );
};


export const CustomOperatorFilter = ({
  column: { filterValue = ['=', null], setFilter, preFilteredRows },
}) => {

  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '20px'}}>
        <SelectSimple
          id='custom-select'
          options={[
            {label: '<', value: '<'},
            {label: '>', value: '>'},
            {label: '=', value: '='}
          ]}
          value={filterValue[0] || '='}
          onChange={(e) => setFilter((old = ['=', null]) => [e.target.value ? e.target.value : undefined, old[1]])}
          placeholder=''
          menuPortalTarget={document.body}
          components={{IndicatorsContainer: () => null}}
          noFeedback={true}
        />
      </div>
      <CustomNumberFormatSimple
        className="form-control custom-form-control"
        value={filterValue[1] || ''}
        onChange={(e) => (setFilter((old = ['=', null]) => [old[0], e.target.value ? e.target.value : e.target.value == 0 ? e.target.value?.toFixed(2) : undefined]))} 
      />
    </div>
  )
}

export const CustomOperatorDateFilter = ({
  column: { filterValue = ['lt', null], setFilter, id },
}) => {
  const { t } = useTranslation();
  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '35px'}}>
        <SelectSimple
          id='custom-select'
          options={[
            {label: '<', value: 'lt'},
            {label: '>', value: 'gt'},
            {label: '=', value: 'eq'},
          ]}
          value={filterValue[0] || 'lt'}
          onChange={(e) => setFilter((old = ['lt', null]) => [e.target.value ? e.target.value : undefined, old[1]])}
          placeholder=''
          menuPortalTarget={document.body}
          components={{IndicatorsContainer: () => null}}
          className="text-center"
        />
      </div>
      <div className={'filters-datepicker'}>
      <DatePickerSimple
        name={id}
        className="form-control custom-form-control"
        selected={filterValue[1] || ''}
        onChange={(e) => (setFilter((old = ['lt', null]) => [old[0], e.target.value ? e.target.value : undefined]))} 
        portalId="root-portal"
        popperClassName="custom-z-index-datepicker-filter"
      />
      </div>
    </div>
  )
}