import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import APIs from '../../api/apiCalls';
import QuestionBankDetails from './QuestionBankDetails';
import { Row, Col, Button } from 'react-bootstrap';
import { PencilFill, Plus, X } from 'react-bootstrap-icons';
import { withToast } from '../../api/util';
import Questions from '../Questions';
import { DeleteModal } from '../../components/Modal';
import { CustomTable } from '../../components/ReactTable/ReactTable';
import { VirtualizedTable } from '../../components/ReactTable/ReactTableVirtualized';

const QuestionBanks = () => {  
  const { t } = useTranslation();
  const [questionBanks, setQuestionBanks] = useState([]);
  const [selectedQuestionBank, setSelectedQuestionBank] = useState(null);
  const [showModal, setShowDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      getQuestionBanks()
    };
    fetchData();
  }, []);

  const getQuestionBanks = async() =>  {
    try {
      let res = await APIs.getQuestionBanks();
      setQuestionBanks(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const getQuestionBankById = async(id) =>  {
    try {
      let res = await APIs.getQuestionBankById(id);
      return res.data
    } catch (err) {
      console.error(err);
    }
  }

  const createQuestionBank = async(obj) =>  {
    setShowDetailsModal(false);
    try {
      await withToast(APIs.createQuestionBank(obj));
      getQuestionBanks();
    } catch (err) {
      console.error(err);
    }
  }

  const editQuestionBank = async(id, obj) =>  {
    setShowDetailsModal(false);
    try {
      await withToast(APIs.editQuestionBank(id, obj));
      getQuestionBanks();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteQuestionBank = async(id) =>  {
    try {
      await withToast(APIs.deleteQuestionBank(id));
      setSelectedQuestionBank(null);
      setShowDeleteModal(false);
      getQuestionBanks();
    } catch (err) {
      console.error(err);
    }
  }

  const cell = (row) => {
    return(
      <div className="pull-right">       
          {row.value}
      </div>
    )
  }

  const columns = [
    {
      Header: t('name'),
      accessor: "name",
      Cell: cell,
      width: 100,
      maxWidth: 100,
      minWidth: 50,
    },   
    {
      Header: t('language'),
      accessor: "language",
      Cell: (row) => {
        return(
          <div>       
            {row.value == 'en' ? t('en') : t('hu')}
          </div>
        )
      },
      width: 'auto',
      width: 50,
      maxWidth: 50,
      minWidth: 50,
    }, 
    {
      Header: "",
      accessor: "actions",
      Cell: (row) => {
        return (
          <div className="text-center">
            <PencilFill className="me-1 custom-icon" color="gray" size={13} 
              onClick={() => {setShowDetailsModal(true), setSelectedQuestionBank(row.row.original)}}
            />
            <X className="custom-icon" color="red" size={20}
              onClick={() => {setShowDeleteModal(true), setObjToDelete(row.row.original.id)}}
            />
          </div>
        );
      },      
      disableFilters: true,
      width: 50,
      minWidth: 50,
      minWidth: 50,
    }   
  ]
  

  const data = React.useMemo(() => questionBanks)
  return(
    <>
      <h3>{t('question_banks')}</h3>
      <Button size="sm" className="mb-2 custom-button" onClick={()=>{setShowDetailsModal(true), setSelectedQuestionBank(null)}}>
          <span><Plus color="white" size={20}/></span>{t('buttons.add')}
      </Button>
      <Row>
        <Col lg={2}>
          <div>
            <VirtualizedTable
              columns={columns}
              data={data}
              onSelect={async(data) => {              
                if(data) {
                  setSelectedQuestionBank(await getQuestionBankById(data));
                }
              }}
              selectedId={selectedQuestionBank?.id}
              height={data.length > 20 ? 585 : data.length * 26}
            />
          </div>     
        </Col>
        {showModal && 
          <QuestionBankDetails 
            selectedRow={selectedQuestionBank} 
            onCreate={obj => createQuestionBank(obj)}
            onEdit={obj => editQuestionBank(obj.id, obj)}
            onClose={() => setShowDetailsModal(false)}
          />
        }
        <Col lg={10}>
          {selectedQuestionBank && 
          <Questions 
            questionBank={selectedQuestionBank?.id}
            language={selectedQuestionBank?.language}
          />}
        </Col>
      </Row>
      {showDeleteModal && <DeleteModal 
        onOK={() => deleteQuestionBank(objToDelete)}
        onCancel={() => setShowDeleteModal(false)}
      />}
    </>
  )
}
export default QuestionBanks;
