import React, { useState } from 'react';
import { Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const TitleComponent = ({ title, language, setLanguage }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('hu');

  return (
    <Row>
      <Col lg={3}>
        <h3>
          {title} {t(language)}
        </h3>
      </Col>
      <Col lg={4}>
        <ToggleButtonGroup
          type="radio"
          name="options"
          defaultValue={'hu'}
          onChange={(val) => {
            setValue(val);
            setLanguage(val);
          }}
          value={value}
        >
          <ToggleButton
            id={'hu'}
            className={
              value === 'hu' ? 'toggle-button-active' : 'toggle-button'
            }
            value="hu"
          >
            {t('hu')}
          </ToggleButton>
          <ToggleButton
            id={'en'}
            className={
              value === 'en' ? 'toggle-button-active' : 'toggle-button'
            }
            value="en"
          >
            {t('en')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Col>
    </Row>
  );
};
