import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row } from 'react-bootstrap';
import CheckpointAPIs from '../../api/checkpoints';
import CustomModal from '../../components/Modal';
import {
  FormEditor,
  FormTextField,
} from '../../components/FormField';
import { Formik } from 'formik';
import * as yup from 'yup';
import APIs from '../../api/apiCalls';
import {
  FileBrowser,
  MultipleImageBrowser,
} from '../../components/FileBrowser';
import DisplayImages from './DisplayImages';

const CheckpointDetails = (props) => {
  const { selectedRow, onCancel, onCreate, language, onEdit, onRefresh } = props;
  const [checkpointTmp, setCheckpointTmp] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const formRef = useRef();

  const initialState = {
    name: '',
    description: '',
    images: [],
    audio: '',
    transcript: '',
    preview: null
  };

  const schema = yup.object({
    name: yup
      .string()
      .max(255)
      .required(t('validation.is_required', { obj: t('title') })),
    description: yup.string().max(256),
    images: yup.array().min(1, t('minimumOneImage')),
    audio: yup
      .string()
      .max(255)
      .required(t('validation.is_required', { obj: t('audio') })),
  });

  useEffect(() => {
    const setData = async () => {
      if (selectedRow?.id) {
        const row = await getCheckpointById(selectedRow.id);
        setCheckpointTmp(row);
      } else {
        setCheckpointTmp(initialState);
      }
      setDefaultImage({
        image: selectedRow?.preview,
        index: null
      });
    };
    setData();
  }, [selectedRow, refresh]);

  const getCheckpointById = async (id) => {
    try {
      const res = await CheckpointAPIs.getCheckpointById(id, language);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const doSubmit = (values) => {
    if (formRef.current.isValid) {
      if (values?.id) {
        onEdit(getObjectToSave(values));
      } else {
        onCreate(getObjectToSave(values));
      }
    }
  };

  const getObjectToSave = (checkpoint) => {
    const checkpointTmp = checkpoint;
 
    if(checkpointTmp.audio instanceof File == false) {
      delete checkpointTmp.audio
    }

    checkpointTmp.main_image_idx = defaultImage.index + 1;

    const imagesTmp = checkpointTmp?.images
      .filter((img) => img.file)
      .map((im) => im.file);

    checkpointTmp.images = imagesTmp;

    delete checkpointTmp.preview;
    return checkpointTmp;
  };

  const renderForm = (values, setFieldValue) => {
    return (
      <>
        <Row>
          <Col lg={6}>
            <FormTextField title={t('name')} name={'name'} />
          </Col>
          <Col lg={6}>
            {values?.average_rating ? (
              <FormTextField
                title={t('average_rating')}
                name={'average_rating'}
                disabled={true}
              />
            ) : (
              <div className="mt-4">{t('no_rating_yet')}</div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormTextField
              title={t('description')}
              name={'description'}
              as="textarea"
              rows={6}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FileBrowser
              title={t('audio')}
              name={'audio'}
              filename={
                values?.audio?.name ? values?.audio?.name : values?.audio
              }
              onChange={(e) => setFieldValue('audio', e.target.files[0])}
              acceptedType=".mp3,.mp4"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormEditor
              title={t('transcript')}
              name={'transcript'}
              savedContent={checkpointTmp?.transcript}
              height={300}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <MultipleImageBrowser
              title={t('images')}
              name={'images'}
              preview={true}
              files={values?.images}
              onChange={(event) => {
                const itemArray = Array.from(event.target.files).map((file) => {
                  return {
                    file,
                    display: URL.createObjectURL(file),
                  };
                });
                setFieldValue('images', values?.images.concat(itemArray));
              }}
            />
          </Col>
          <DisplayImages
            images={values?.images}
            onRefresh={() => {
              setRefresh(!refresh);
              onRefresh();
            }}
            defaultImage={defaultImage}
            selectedCheckpoint={selectedRow}
            onDeleted={(e) => {
              const filteredImages = values.images.filter((img) => {
                if (img.file) {
                  if (img.file.name !== e.file.name) {
                    return img;
                  }
                } else {
                  return img;
                }
              });
              setFieldValue('images', filteredImages);
            }}
            onChangeDefaultImage={(image) => {
              setDefaultImage(image);
              setFieldValue('preview', image);
            }}
          />
        </Row>
      </>
    );
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        innerRef={formRef}
        enableReinitialize
        initialValues={checkpointTmp}
        validateOnMount={true}
        onSubmit={(values) => {
          doSubmit(values);
        }}
      >
        {({ handleSubmit, values, dirty, setFieldValue }) => (
          <CustomModal
            title={
              selectedRow?.id
                ? t('edit_obj', { obj: 'checkpoint' })
                : t('new_obj', { obj: 'checkpoint' })
            }
            content={
              <Form id="form-info-content">
                {renderForm(values, setFieldValue)}
              </Form>
            }
            contentStyle={{ fontSize: '12px' }}
            onCancel={onCancel}
            onOK={() => handleSubmit()}
            okButtonText={t('buttons.save')}
            disabledOKButton={!dirty}
            size={'xl'}
            modified={dirty}
          />
        )}
      </Formik>
    </>
  );
};
export default CheckpointDetails;
