import React from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from "../LoginContext";

export const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};