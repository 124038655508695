import React from 'react';
import toast from '../components/Toaster';

const responseAccessor = x => x.message;
const errorAccessor = x => x.message;
const success = msg => msg && toast.success(<div>{msg}</div>);
const failure = msg => msg && toast.error(<div>{msg}</div>);
const warning = msg => msg && toast.warning(<div>{msg}</div>)

const withToast = async (promise, getResponse = responseAccessor,
    getError = errorAccessor) => {
  try {
    const res = await promise;
    if(res.status === 'warning') {
      warning(getResponse(res));
    }
    else {
      success(getResponse(res));
    }
    return res;
  } catch(err) {
    failure(getError(err));
    throw err;
  }
}

const withToastOnError = async (promise, getError = errorAccessor) => {
  try {
    return await promise;
  } catch(err) {
    failure(getError(err));
    throw err;
  }
}

const jsonToFormData = object => {
  const data = new FormData();
  Object.entries(object).forEach(
    ([k, v]) =>{
      if(v instanceof File) {
        data.append(k, v, v.name)
      }
      else if(v instanceof Array ){
        for (const tag of v) {
          if(tag instanceof File) {
            data.append(k, tag, tag.name);
          }
          else if(tag instanceof Object){
            data.append(k, JSON.stringify(tag));
          }
          else {
            data.append(k, tag);
          }
        }
      }
      else if(v instanceof Object ) {
        data.append(k, JSON.stringify(v))
      }
      else {
        data.append(k, v) 
      }
    }
  );
  return data;
}

export { withToast, withToastOnError, jsonToFormData }
