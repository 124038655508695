export const statusOptions = (t) => {
  return [
    { label: t('development'), value: 'development' },
    { label: t('testing'), value: 'testing' },
    { label: t('active'), value: 'active' },
    { label: t('expired'), value: 'expired' },
  ];
};

export const statusOptionsFilter = (t) => {
  return [
    { label: t('development'), value: 'development' },
    { label: t('testing'), value: 'testing' },
    { label: t('active'), value: 'active' },
    { label: t('expired'), value: 'expired' },
  ];
};
export const questionTypes = (t) => {
  return [
    { label: t('multichoice'), value: 'multichoice' },
    { label: t('singlechoice'), value: 'singlechoice' },
    { label: t('ordering'), value: 'ordering' },
    { label: t('pairing'), value: 'pairing' },
    { label: t('dnd'), value: 'dnd' },
    { label: t('free'), value: 'free' },
  ];
};

export const difficulty = (t) => {
  return [
    { label: t('easy'), value: 2 },
    { label: t('medium'), value: 5 },
    { label: t('hard'), value: 8 },
  ];
};


export const qualityOptions = (t) => {
  return [
    {label: t('quality_bu'), value: 'bu'},
    {label: t('quality_pp'), value: 'pp'},
    {label: t('quality_proof_like'), value: 'proof_like'},
    {label: t('quality_unc'), value: 'unc'},
    {label: t('quality_patinated'), value: 'patinated'},
  ];
}

export const purityOptions = (t) => {
  return [
    {label: '-', value: ''},
    {label: t('purity_500'), value: '.500'},
    {label: t('purity_584'), value: '.584'},
    {label: t('purity_640'), value: '.640'},
    {label: t('purity_750'), value: '.750'},
    {label: t('purity_800'), value: '.800'},
    {label: t('purity_900'), value: '.900'},
    {label: t('purity_925'), value: '.925'},
    {label: t('purity_986'), value: '.986'},
    {label: t('purity_999'), value: '.999'},
  ];
}

export const materialOptions = (t) => {
  return [
    {label: '-', value: ''},
    {label: t('gold'), value: 'gold'},
    {label: t('silver'), value: 'silver'},
    {label: t('non_ferrous_metal'), value: 'non_ferrous_metal'},
  ];
}

export const durationOptions = (t) => {
  return [
    { label: t('30min'), value: 30 },
    { label: t('45min'), value: 45 },
    { label: t('60min'), value: 60 },
  ];
}

export const routeTypeOptions = (t) => {
  return [
    { label: t('thematic'), value: 'thematic' },
    { label: t('time-based'), value: 'time-based' },
  ];
}

export const colorsOptions = (t) => {
  return [
    {label: t('orange'), value: 'FAA032'},
    {label: t('lightblue'), value: '00AAE6'},
    {label: t('lila'), value: 'AF4196'},
    {label: t('green'), value: '00AFA5'},
    {label: t('yellow'), value: 'D2E150'},
    {label: t('red'), value: 'F0374B'},
    {label: t('gray'), value: 'EDEDED'},
    {label: t('brown'), value: 'F7F5F2'},
    {label: t('blue'), value: '23324B'},
  ];
}
